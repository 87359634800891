import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import prices from './prices';
import symbols from './symbols';
import lists from './lists';

export default combineReducers({
    prices,
    symbols,
    auth,
    lists,
});
