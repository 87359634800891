import React from 'react';
import bannerFreeData from '../assets/banner-canliveri.jpg';
import bannerInternational from '../assets/banner-uluslararasi.jpg';
import bannerFintables from '../assets/banner-fintables.jpg';

import { TopStocks, CurrencyCalculator, InfoAd } from '../components';
import { Link } from 'react-router-dom';

const HasRightSideLayout = ({ children }) => {
    return (
        <div className="flex">
            <div className="flex-grow w-full lg:w-8/12">{children}</div>
            <div className="hidden lg:block pl-6 w-4/12">
                <div className="mb-4">
                    <TopStocks />
                </div>
                <div className="mb-4">
                    <CurrencyCalculator />
                </div>

                <a
                    href="https://hesapac.infoyatirim.com/?kaynak=canliveri-borsaekranim&leadtype=1&leaddagitim=hisse&utm_campaign=ucretsiz-data"
                    className="block mb-4 overflow-hidden rounded-lg shadow"
                    target="_blank"
                    rel="nofollow noopener">
                    <img src={bannerFreeData} alt="" className="w-full" />
                </a>

                <a
                    href="https://hesapac.infoyatirim.com/?kaynak=ydisiborsa-borsaekranim&leadtype=1&leaddagitim=hisse&utm_campaign=yborsa"
                    className="block mb-4 overflow-hidden rounded-lg shadow"
                    target="_blank"
                    rel="nofollow noopener">
                    <img src={bannerInternational} alt="" className="w-full" />
                </a>

                {/*<InfoAd />*/}
            </div>
        </div>
    );
};

export default HasRightSideLayout;
