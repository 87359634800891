import React from 'react';

const Range = ({ value }) => (
    <>
        <style>
            {
                '\
        .gradient-range {\
    background:    -moz-linear-gradient(left, green, yellow, red); /* FF3.6+ */\
    background:        -webkit-gradient(linear, left top, right top, color-stop(0%, red), color-stop(50%, yellow), color-stop(100%, green)); /* Chrome,Safari4+ */\
    background: -webkit-linear-gradient(left, green, yellow, red); /* Chrome10+,Safari5.1+ */\
    background:      -o-linear-gradient(left, green, yellow, red); /* Opera 11.10+ */\
    background:     -ms-linear-gradient(left, green, yellow, red); /* IE10+ */\
    background:         linear-gradient(to right, green, yellow, red); /* W3C */\
'
            }
        </style>
        <div className="gradient-range h-3 rounded-lg relative mb-20">
            <div
                className="rounded-full w-5 h-5 bg-gray-800 border-2 b-gray-200 shadow-md absolute -top-1"
                style={{ left: `${value}%`, transform: 'translateX(-50%)' }}>
                <div
                    className="top-6 absolute w-40 bg-white shadow-md text-center rounded-md py-1"
                    style={{ transform: 'translateX(-50%)' }}>
                    <strong className="text-sm font-medium text-gray-600">
                        Risk profil puanınız
                    </strong>
                    <div className="text-xl font-bold">{Math.round(value)}</div>
                </div>
            </div>
        </div>
    </>
);

export default Range;
