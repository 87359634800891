import React from 'react';
import { Link } from 'react-router-dom';

import { useRequest } from '../../hooks';
import { NewsList, SectionHeader } from '../../components';

const prepareResponse = res => {
    return res.slice(0, 9);
};

const News = () => {
    const [{ response, loading }] = useRequest(`news/?limit=${10}`, { prepareResponse });

    return (
        <div className="bg-white rounded-lg shadow">
            <SectionHeader
                title="Haberler"
                description="Finans ve iş dünyasından son dakika gelişmeler"
            />

            <NewsList news={response} loading={loading} />

            <Link
                className="px-6 text-blue-600 w-full font-medium py-4 inline-block border-t border-gray-200"
                to="/haberler">
                Tüm Haberleri Görüntüle &rarr;
            </Link>
        </div>
    );
};

export default News;
