import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import logo from '../../assets/borsaekranim-logo.png';
import { useRequest } from '../../hooks';
import { Input, ErrorAlert } from '../../components';
import { login } from '../../utils/authMethods';
import { toast, Button } from '../../components';
import { required, minLength } from '../../utils/validation';
import { isMobile } from '../../utils/helpers';

const ResetPassword = () => {
    const { register, handleSubmit, errors, getValues } = useForm({ criteriaMode: 'all' });
    const [{ loading, errors: apiErrors }, resetPasswordRequest] = useRequest({
        url: '/auth/password/reset/complete/',
        method: 'post',
    });
    const { userId, token } = useParams();

    const onSubmit = ({ password }) => {
        resetPasswordRequest({ user_id: userId, token, password }, res => {
            toast.simple({
                title: 'Başarılı!',
                description: 'Şifreniz başarıyla değiştirildi.',
                icon: 'success',
            });
            login(res.data);
        });
    };

    return (
        <>
            <div>
                <Link to="/" title="Borsa Ekranım">
                    <img className="h-10 w-auto" src={logo} alt="Borsa Ekranım" />
                </Link>

                <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                    Şifrenizi Sıfırlayın
                </h2>
                <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                    Yeni şifrenizi belirleyebilirsiniz.
                </p>
            </div>

            <div className="mt-8">
                <div className="mt-6">
                    <ErrorAlert className="mb-8" text={apiErrors.detail} />{' '}
                    {/* TODO: pass error object and list errors */}
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            id="password"
                            name="password"
                            label="Şifre"
                            containerClass="mt-6"
                            type="password"
                            autoComplete="new-password"
                            autoFocus={!isMobile}
                            errors={errors.password?.types || apiErrors.password}
                            ref={register({
                                required: required('Şifre'),
                                minLength: minLength('Şifre', 6),
                            })}
                        />
                        <Input
                            id="passwordAgain"
                            name="passwordAgain"
                            label="Şifre (tekrar)"
                            containerClass="mt-6"
                            type="password"
                            autoComplete="new-password"
                            errors={errors.passwordAgain?.types}
                            ref={register({
                                validate: value => {
                                    if (value === getValues()['password']) {
                                        return true;
                                    } else {
                                        return 'Şifre alanları birbiriyle uyuşmuyor.';
                                    }
                                },
                            })}
                        />

                        <div className="mt-6">
                            <Button
                                label="Onayla"
                                loading={loading}
                                type="submit"
                                className="w-full"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
