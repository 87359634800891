// @flow
export const getSymbolPath = (symbol?: { id: string, prefix: string }) =>
    symbol ? `/${getSymbolRouteName(symbol.prefix)}/${symbol.id}` : '#';

export const getSymbolRouteName = (prefix: string) => {
    let routeName;

    switch (prefix) {
        case 'IMKBH':
            routeName = 'hisse';
            break;
        case 'IMKBX':
            routeName = 'endeks';
            break;
        case 'VIP':
            routeName = 'viop';
            break;
        case 'FX':
            routeName = 'forex';
            break;
        default:
            routeName = 'sembol';
            break;
    }

    return routeName;
};
