import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { QuickViewer, PageHeader } from '../../components';
import { selectByPrefix } from '../../stores/symbols';

const description = `Altın sayfamızda ons bazında altın fiyatlarını canlı olarak takip edebilirsiniz. Canlı altın fiyatlarının yanı sıra gram altın fiyatlarını da anlık olarak görüntüleyebilirsiniz.  Altın sayfamızda platinum, nikel, gümüş gibi diğer metal fiyatlarını da canlı ve anlık olarak bulabilirsiniz. 

Ayrıca canlı altın fiyatını grafik üzerinde de görüntüleyebilirsiniz. Altın ne kadar? diye soruyorsanız Borsa Ekranım üzerinden anlık takip yapabilirsiniz. Altın fiyatının gün içi değişim yüzdelerini görüntüleyebilirsiniz.`;

const Gold = () => {
    const { t } = useTranslation();
    const symbols = useSelector(state => selectByPrefix(state, 'KIYM'));
    const symbolIds = useMemo(() => symbols.map(symbol => symbol.id), [symbols]);

    return (
        <>
            <Helmet>
                <title>Canlı Altın Fiyatları, Güncel Altın Piyasası</title>
                <meta
                    name="description"
                    content="Altın sayfamızda ons bazında altın fiyatlarını canlı olarak takip edebilirsiniz. Canlı altın fiyatlarının yanı sıra gram altın fiyatlarını da anlık olarak görüntüleyebilirsiniz."
                />
            </Helmet>

            <PageHeader title="Altın Fiyatları" />

            <div className="bg-white rounded-lg shadow">
                <QuickViewer codes={symbolIds} />
            </div>

            <div className="bg-white mt-4 w-full rounded-lg shadow px-4 pt-4 pb-2">
                {description.split('\n').map((paragraph, i) => (
                    <p className="mb-2" key={`${i}`}>
                        {paragraph}
                    </p>
                ))}
            </div>
        </>
    );
};

export default Gold;
