import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components';

const FooterCta = () => (
    <div className="max-w-8xl mx-auto px-4 sm:px-6 py-4 lg:py-12">
        <div className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Borsayı anlık takip etmek ister misiniz?
            <br />
            <span className="text-blue-600">
                2 ay ücretsiz anlık fiyat hediyemizi hemen alabilirsiniz.
            </span>
        </div>
        <div className="mt-8 flex">
            <Link to="/kayit-ol">
                <Button size="xl" label="Üye Ol" />
            </Link>
            <Link to="/tanitim">
                <Button size="xl" color="lightBlue" className="ml-3" label="Detaylı bilgi" />
            </Link>
        </div>
    </div>
);

export default FooterCta;
