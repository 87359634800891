import { useMemo } from 'react';
import useUser from './useUser';

export const useLicence = licence => {
    const user = useUser();

    return useMemo(() => user?.profile?.active_licences?.indexOf(licence) > -1, [licence, user]);
};

export default useLicence;
