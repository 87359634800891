import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <>
            <Helmet>
                <title>Hakkımızda</title>
                <meta
                    name="description"
                    content="1994 yılından bu yana sermaye piyasaları alanında faaliyet gösteren İnfo Yatırım Menkul Değerler A.Ş. tüm yetki belgeleri ile bireysel ve kurumsal yatırımcılara hizmet vermektedir."
                />
            </Helmet>

            <div class="relative">
                <div class="bg-white overflow-hidden">
                    <div class="relative max-w-8xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div class="hidden lg:block absolute top-0 bottom-0 left-3/4 w-screen"></div>
                        <div class="mx-auto text-base max-w-prose lg:max-w-none">
                            <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                                Hakkımızda
                            </p>
                        </div>
                        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div class="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
                                <svg
                                    class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                                    width="404"
                                    height="384"
                                    fill="none"
                                    viewBox="0 0 404 384">
                                    <defs>
                                        <pattern
                                            id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                            x="0"
                                            y="0"
                                            width="20"
                                            height="20"
                                            patternUnits="userSpaceOnUse">
                                            <rect
                                                x="0"
                                                y="0"
                                                width="4"
                                                height="4"
                                                class="text-gray-200"
                                                fill="currentColor"
                                            />
                                        </pattern>
                                    </defs>
                                    <rect
                                        width="404"
                                        height="384"
                                        fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                                    />
                                </svg>
                                <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                                    <figure>
                                        <div class="relative pb-7/12 lg:pb-0">
                                            <img
                                                src="https://cdn.statically.io/img/infoyatirim.com/f=auto/wp-content/uploads/bb-plugin/cache/Biz-Kimiz-square.png"
                                                alt=""
                                                width="1184"
                                                height="1376"
                                                class="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                                            />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div>
                                <div class="text-base max-w-prose mx-auto lg:max-w-none">
                                    <p class="text-lg leading-7 text-gray-500 mb-5">
                                        1994 yılından bu yana sermaye piyasaları alanında faaliyet
                                        gösteren İnfo Yatırım Menkul Değerler A.Ş. tüm yetki
                                        belgeleri ile bireysel ve kurumsal yatırımcılara hizmet
                                        vermektedir.
                                    </p>
                                </div>
                                <div class="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                                    <p>
                                        İnfo Yatırım bireysel ve kurumsal yatırımcılara, sermaye
                                        piyasası araçlarının alım – satım aracılığının yanı sıra,
                                        yatırım danışmanlığı, portföy yönetimi ve kurumsal finansman
                                        hizmetleri sunmaktadır.
                                    </p>
                                    <p>
                                        İnfo Yatırım, Borsa İstanbul A.Ş. Pay Piyasası, Borçlanma
                                        Araçları Piyasası, Vadeli İşlem ve Opsiyon Piyasası ve
                                        Kaldıraçlı Alım Satım İşlemleri’nde aracılık hizmeti
                                        vermekte olup, faaliyet gösterdiği alanlarda etkinliğini
                                        giderek artırmaktadır.
                                    </p>
                                    <p>
                                        30.09.2011 tarihinde halka arz edilen şirket Borsa
                                        İstanbul’da İNFO koduyla işlem görmektedir.
                                    </p>
                                    <p>
                                        Güçlü özsermayesini, piyasalardaki tecrübe ve uzman
                                        kadrosunu teknolojik altyapı ile birleştiren İnfo Yatırım,
                                        Genel Müdürlük haricinde tamamı 2019 Nisan dönemi sonrası
                                        sonrası açılan İstanbul’da Ataşehir, Beşiktaş, Beylikdüzü,
                                        Göztepe, Göktürk ve Tuzla, Ankara’da Ankara, Çankaya ve
                                        Söğütözü ile Balıkesir, İzmir, Kırıkkale, Samsun, Bursa ve
                                        Adana İrtibat Büroları aracılığıyla 16 noktada hizmet
                                        vermektedir.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-gray-50 overflow-hidden">
                    <div class="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <svg
                            class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
                            width="404"
                            height="784"
                            fill="none"
                            viewBox="0 0 404 784">
                            <defs>
                                <pattern
                                    id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                    patternUnits="userSpaceOnUse">
                                    <rect
                                        x="0"
                                        y="0"
                                        width="4"
                                        height="4"
                                        class="text-gray-200"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width="404"
                                height="784"
                                fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
                            />
                        </svg>

                        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div class="lg:col-span-1">
                                <h3 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                    Neden İnfo Yatırım?
                                </h3>
                                <p class="text-gray-500 mt-5">
                                    Yatırımcılarımızın beklentilerini müşteri odaklı hizmet
                                    anlayışımızla en üst düzeyde karşılamayı amaçlıyoruz.
                                </p>
                            </div>
                            <div class="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0">
                                <div>
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                            />
                                        </svg>
                                    </div>
                                    <div class="mt-5">
                                        <h4 class="text-lg leading-6 font-medium text-gray-900">
                                            Güçlü Sermaye ve Halka Açıklık
                                        </h4>
                                        <p class="mt-2 text-base leading-6 text-gray-500">
                                            İnfo Yatırım, sermayesi güçlü ve halka açık Hedef GSYO,
                                            Seyitler Kimya ve İdealist Danışmanlık şirketlerini de
                                            bünyesinde bulunduran Hedef Grubu iştirakidir. 2011
                                            yılında halka arz olan İnfo Yatırım, Borsa İstanbul'da
                                            INFO koduyla işlem görmektedir.
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-10 sm:mt-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                                            />
                                        </svg>
                                    </div>
                                    <div class="mt-5">
                                        <h4 class="text-lg leading-6 font-medium text-gray-900">
                                            Güvenilir ve Şeffaf olmamız
                                        </h4>
                                        <p class="mt-2 text-base leading-6 text-gray-500">
                                            Yatırımcılarımıza piyasada meydana gelen herhangi bir
                                            ekonomik olay, gelişme veya düzenleme hakkında, tam
                                            olarak bilgi sahibi olmaları için şeffaf, adil ve dürüst
                                            bir hizmet sunmaktayız.
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-10 sm:mt-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                            />
                                        </svg>
                                    </div>
                                    <div class="mt-5">
                                        <h4 class="text-lg leading-6 font-medium text-gray-900">
                                            Birebir Hizmet Yakın Destek
                                        </h4>
                                        <p class="mt-2 text-base leading-6 text-gray-500">
                                            Finansal piyasalara yönelik tüm ihtiyaçlarınıza yakın
                                            hizmetimizle destek oluyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-10 sm:mt-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                    </div>
                                    <div class="mt-5">
                                        <h4 class="text-lg leading-6 font-medium text-gray-900">
                                            5 Gün 24 Saat Çözüm Odaklı Müşteri Desteği
                                        </h4>
                                        <p class="mt-2 text-base leading-6 text-gray-500">
                                            Yatırımcılarımıza 5 gün 24 saat hızlı işlem altyapımız
                                            ile kişiye özel hizmet ve analiz desteği sunmaktayız.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
