import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectById as selectSymbolById } from '../../stores/symbols';
import logo from '../../assets/borsaekranim-logo.png';
import {
    LightningBoltIcon,
    PresentationChartLineIcon,
    AnnotationIcon,
    BellIcon,
} from '@heroicons/react/outline';

const OG = () => {
    const { symbol: code } = useParams();
    const symbol = useSelector(state => selectSymbolById(state, code));

    if (!symbol) return null;

    return (
        <div className="og bg-white border-t-4 border-blue-600" style={{ width: 600 }}>
            <div className="flex items-center p-7">
                <div className="flex-grow">
                    <span className="text-3xl font-bold text-blue-800">${symbol.id}</span>
                </div>
                <img src={logo} className="h-12" />
            </div>

            <div className="text-lg text-gray-600 mt-4 px-7 -mt-5">{symbol.title}</div>

            <div className="flex mb-3 mt-6 mx-7 space-x-3">
                <div className="flex-1 flex flex-col items-center bg-blue-100 rounded py-3">
                    <LightningBoltIcon className="w-6 h-6" />
                    <div className="text-gray-600 mt-1.5">Canlı Fiyat</div>
                </div>
                <div className="flex-1 flex flex-col items-center bg-blue-100 rounded py-3">
                    <PresentationChartLineIcon className="w-6 h-6" />
                    <div className="text-gray-600 mt-1.5">Grafik</div>
                </div>
                <div className="flex-1 flex flex-col items-center bg-blue-100 rounded py-3">
                    <AnnotationIcon className="w-6 h-6" />
                    <div className="text-gray-600 mt-1.5">Analiz</div>
                </div>
                <div className="flex-1 flex flex-col items-center bg-blue-100 rounded py-3">
                    <BellIcon className="w-6 h-6" />
                    <div className="text-gray-600 mt-1.5">Haber</div>
                </div>
            </div>

            <div className="pb-5 pt-7 text-sm text-gray-500 italic text-center">
                2 ay ücretsiz canlı fiyat verisi hediye
            </div>
        </div>
    );
};

export default OG;
