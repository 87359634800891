import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const SymbolHistoricalChart = ({ data, code }) => (
    <ResponsiveLine
        data={data}
        enableArea
        enableGridX={false}
        gridYValues={5}
        areaOpacity={0.2}
        colors={['rgb(63, 131, 248)']}
        margin={{ top: 25, right: 25, bottom: 45, left: 45 }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        xScale={{
            type: 'time',
            useUTC: true,
            precision: 'day',
        }}
        xFormat="time:%b %d"
        axisBottom={{
            format: '%b %d',
            tickValues: 5,
            legendOffset: -12,
        }}
        axisLeft={{
            tickValues: 5,
            orient: 'left',
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
        }}
        enablePoints={false}
        useMesh={true}
        tooltip={({
            point: {
                data: { xFormatted, yFormatted },
                serieColor,
            },
        }) => (
            <div className="shadow-md px-2 py-1 pointer-events-none bg-gray-100 rounded-sm text-sm">
                <div className="text-xs">
                    <svg
                        className="w-3 h-3 -mt-0.5 inline text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                            clipRule="evenodd"></path>
                    </svg>{' '}
                    {xFormatted}
                </div>
                {code}: <span className="font-medium">{yFormatted}</span>
            </div>
        )}
    />
);

export default SymbolHistoricalChart;
