import { useEffect, useState } from 'react';

const subscribers = new Set();

let responsiveConfig = [
    { minWidth: 0, key: 'xs' },
    { minWidth: 640, key: 'sm' },
    { minWidth: 768, key: 'md' },
    { minWidth: 1024, key: 'lg' },
    { minWidth: 1280, key: 'xl' },
];

let breakPoint = undefined;

function calculate() {
    const width = window.innerWidth;
    let newBreakPoint = undefined;
    let shouldUpdate = false;

    responsiveConfig.forEach((obj, i) => {
        if (
            width >= obj.minWidth &&
            (responsiveConfig[i + 1] ? width < responsiveConfig[i + 1].minWidth : true)
        ) {
            newBreakPoint = obj.key;
        }

        if (breakPoint !== newBreakPoint) {
            shouldUpdate = true;
        }
    });

    if (shouldUpdate) {
        breakPoint = newBreakPoint;
    }
}

calculate();

window.addEventListener('resize', () => {
    const oldBreakPoint = breakPoint;
    calculate();

    if (oldBreakPoint === breakPoint) {
        return;
    }

    for (const subscriber of subscribers) {
        subscriber();
    }
});

const useResponsive = () => {
    const [state, setState] = useState(breakPoint);

    useEffect(() => {
        const subscriber = () => {
            setState(breakPoint);
        };

        subscribers.add(subscriber);

        return () => {
            subscribers.delete(subscriber);
        };
    }, []);

    return state;
};

export default useResponsive;
