import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userReceived } from '../stores/auth';
import { toast } from '../components';
import useRequest from './useRequest';

export const useFavorites = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const favorites = useMemo(() => user?.profile.favorites ?? [], [user]);
    const [{ loading }, addFavoriteRequest] = useRequest({
        url: '/favorites/',
        method: 'put',
    });

    const isFavorite = useCallback(symbol => favorites.includes(symbol), [favorites]);
    const addFavorite = useCallback(
        symbol => {
            addFavoriteRequest(
                { codes: [...favorites.filter(fav => symbol !== fav), symbol] },
                d => {
                    dispatch(userReceived(d.data.data));
                },
                err => {
                    if (err.status === 401) {
                        toast.simple({
                            title: t('error.unauthorizedAction'),
                            description: t('error.loginRequired'),
                            icon: 'ban',
                        });
                    }
                },
            );
        },
        [addFavoriteRequest, dispatch, favorites, t],
    );
    const syncFavorites = useCallback(
        symbols => {
            addFavoriteRequest(
                { codes: symbols },
                d => {
                    dispatch(userReceived(d.data.data));
                },
                err => {
                    if (err.status === 401) {
                        toast.simple({
                            title: t('error.unauthorizedAction'),
                            description: t('error.loginRequired'),
                            icon: 'ban',
                        });
                    }
                },
            );
        },
        [addFavoriteRequest, dispatch, t],
    );
    const deleteFavorite = useCallback(
        symbol => {
            addFavoriteRequest({ codes: favorites.filter(d => d !== symbol) }, d => {
                dispatch(userReceived(d.data.data));
            });
        },
        [addFavoriteRequest, dispatch, favorites],
    );

    return { favorites, isFavorite, addFavorite, syncFavorites, deleteFavorite, requestLoading: loading };
};

export default useFavorites;
