import React, { useState, useRef } from 'react';
import NumberFormat from 'react-number-format';

import { SectionHeader } from '../components';
import { usePrice } from '../hooks';

const Side = ({ value, onValueChange, currency, onCurrencyChange }) => (
    <div className="relative rounded-md shadow-sm">
        <NumberFormat
            decimalScale={4}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-16 sm:text-sm border-gray-300 rounded-md"
            placeholder="0.00"
            value={value}
            onChange={e => onValueChange(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
            <select
                value={currency}
                onChange={e => onCurrencyChange(e.target.value)}
                className="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-r-md">
                <option>TRY</option>
                <option>USD</option>
                <option>EUR</option>
                <option>GBP</option>
            </select>
        </div>
    </div>
);

const CurrencyCalculator = () => {
    const [leftValue, setLeftValue] = useState('');
    const [leftCurrency, setLeftCurrency] = useState('USD');
    const [rightValue, setRightValue] = useState('');
    const [rightCurrency, setRightCurrency] = useState('TRY');
    const lastDirection = useRef(null);

    const USDTRY = usePrice('USDTRY')?.original?.lastPrice;
    const EURTRY = usePrice('EURTRY')?.original?.lastPrice;
    const TRYGBP = usePrice('TRYGBP')?.original?.lastPrice;
    const EURUSD = usePrice('EURUSD')?.original?.lastPrice;
    const GBPUSD = usePrice('GBPUSD')?.original?.lastPrice;
    const GBPEUR = usePrice('GBPEUR')?.original?.lastPrice;

    const calculate = (fromCurrency, fromValue, toCurrency) => {
        if (fromCurrency === toCurrency) {
            return fromValue;
        } else if (fromCurrency === 'USD' && toCurrency === 'TRY') {
            return fromValue * USDTRY;
        } else if (fromCurrency === 'TRY' && toCurrency === 'USD') {
            return fromValue * (1 / USDTRY);
        } else if (fromCurrency === 'EUR' && toCurrency === 'TRY') {
            return fromValue * EURTRY;
        } else if (fromCurrency === 'TRY' && toCurrency === 'EUR') {
            return fromValue * (1 / EURTRY);
        } else if (fromCurrency === 'GBP' && toCurrency === 'TRY') {
            return fromValue * TRYGBP;
        } else if (fromCurrency === 'TRY' && toCurrency === 'GBP') {
            return fromValue * (1 / TRYGBP);
        } else if (fromCurrency === 'EUR' && toCurrency === 'USD') {
            return fromValue * EURUSD;
        } else if (fromCurrency === 'USD' && toCurrency === 'EUR') {
            return fromValue * (1 / EURUSD);
        } else if (fromCurrency === 'GBP' && toCurrency === 'EUR') {
            return fromValue * GBPEUR;
        } else if (fromCurrency === 'EUR' && toCurrency === 'GBP') {
            return fromValue * (1 / GBPEUR);
        } else if (fromCurrency === 'GBP' && toCurrency === 'USD') {
            return fromValue * GBPUSD;
        } else if (fromCurrency === 'USD' && toCurrency === 'GBP') {
            return fromValue * (1 / GBPUSD);
        }
    };

    const calculateRTL = (rightCurrency, rightValue, leftCur = leftCurrency) => {
        lastDirection.current = 'RTL';
        setLeftValue(calculate(rightCurrency, rightValue, leftCur));
    };

    const calculateLTR = (leftCurrency, leftValue, rightCurrency) => {
        lastDirection.current = 'LTR';
        setRightValue(calculate(leftCurrency, leftValue, rightCurrency));
    };

    const handleLeftValueChange = value => {
        setLeftValue(value);
        calculateLTR(leftCurrency, value, rightCurrency);
    };

    const handleRightValueChange = value => {
        setRightValue(value);
        calculateRTL(rightCurrency, value);
    };

    const handleLeftCurrencyChange = currency => {
        setLeftCurrency(currency);
        calculateLTR(currency, leftValue, rightCurrency);
    };

    const handleRightCurrencyChange = currency => {
        setRightCurrency(currency);
        calculateLTR(leftCurrency, leftValue, currency);
    };

    const changeDirection = () => {
        if (lastDirection.current === 'LTR') {
            setRightCurrency(leftCurrency);
            setLeftCurrency(rightCurrency);
            calculateLTR(rightCurrency, leftValue, leftCurrency);
        } else {
            setRightCurrency(leftCurrency);
            setLeftCurrency(rightCurrency);
            calculateRTL(leftCurrency, rightValue, rightCurrency);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow">
            <SectionHeader title="Döviz Çevirici" />

            <div className="flex py-4 px-4">
                <div className="flex-1 relative">
                    <Side
                        value={leftValue}
                        onValueChange={handleLeftValueChange}
                        currency={leftCurrency}
                        onCurrencyChange={handleLeftCurrencyChange}
                    />
                </div>
                <button onClick={changeDirection} className="text-gray-600 px-2 focus:outline-none">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"></path>
                    </svg>
                </button>
                <div className="flex-1 relative">
                    <Side
                        value={rightValue}
                        onValueChange={handleRightValueChange}
                        currency={rightCurrency}
                        onCurrencyChange={handleRightCurrencyChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CurrencyCalculator;
