import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { NewItem, NewDetailsModalContent, Modal } from '../../components';
import { useRequest } from '../../hooks';

const News = () => {
    const [{ response, loading }] = useRequest(`news/`);
    const [showModal, setShowModal] = useState(false);
    const [selectedNew, setSelectedNew] = useState(null);

    return (
        <>
            <Helmet>
                <title>Haberler</title>
                <meta
                    name="description"
                    content="Finans ve iş dünyasındaki son dakika gelişmelerinden ilk sizin haberiniz olsun."
                />
            </Helmet>

            <div className="bg-white rounded-lg shadow">
                <div className="border-b py-4 border-gray-200">
                    <div className="px-6">
                        <h2 className="text-xl leading-6 font-medium text-gray-900 ">Haberler</h2>
                        <div className="mt-3">
                            <p className="text-md leading-3 text-gray-500">
                                Finans ve iş dünyasından son dakika gelişmeler
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-white pb-6">
                    <div className="relative mx-auto lg:max-w-8xl">
                        <div className="flex flex-col space-y-5 px-5 pt-5">
                            {loading && response.length === 0
                                ? Array.from(Array(10).keys()).map(i => (
                                      <div key={i} className="animate-pulse flex space-x-4">
                                          <div className="flex-1 space-y-2 py-1">
                                              <div className="h-3 bg-gray-100 rounded w-1/6"></div>
                                              <div className="h-3 bg-gray-200 rounded w-5/6"></div>
                                          </div>
                                      </div>
                                  ))
                                : response.map((item, i) => (
                                      <NewItem
                                          onClick={item => {
                                              setSelectedNew(item);
                                              setShowModal(true);
                                          }}
                                          item={item}
                                          key={i}
                                      />
                                  ))}
                        </div>

                        <Modal
                            onClose={() => setShowModal(false)}
                            contentClassName="bg-white p-6 m-8 max-w-prose w-full"
                            show={showModal}>
                            <NewDetailsModalContent
                                onClose={() => setShowModal(false)}
                                newItem={selectedNew}
                                loading={loading}
                            />
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default News;
