import React, { useState, useEffect } from 'react';
import Row from './Row';
import Chart from './Chart';

const QuickViewer = ({ codes }) => {
    const [activeCode, setActiveCode] = useState(codes[0]);

    // Reset selected code when the list of codes change
    useEffect(() => setActiveCode(codes[0]), [codes]);

    return (
        <>
            <div className="h-48 flex justify-center items-center">
                {activeCode && <Chart code={activeCode} />}
            </div>

            <table className="w-full mt-3">
                <thead>
                    <tr className="h-10 text-gray-500 text-sm text-right">
                        <th className="font-medium text-left pl-3">Sembol</th>
                        <th className="font-medium">Son Fiyat</th>
                        <th className="font-medium hidden md:table-cell">Değişim %</th>
                        <th className="font-medium">Değişim</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {codes.map(code => (
                        <Row
                            key={code}
                            code={code}
                            active={activeCode === code}
                            onSelect={() => setActiveCode(code)}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default QuickViewer;
