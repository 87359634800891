import React, { forwardRef } from 'react';
import classNames from 'classnames';

const ErrorText = ({ text }) => (
    <p role="alert" className="mt-2 text-sm text-red-600">
        {text}
    </p>
);

export const checkHasErrors = errors => {
    if (
        (errors instanceof Object && Object.entries(errors).length > 0) ||
        (errors instanceof Array && errors.length > 0) ||
        (errors instanceof String && errors.length > 0)
    ) {
        return true;
    }

    return false;
};

const Input = forwardRef(
    (
        {
            value,
            onChange,
            label,
            id,
            className,
            placeholder = '',
            errors = [], // string | Object<{[errorType: string]: string}> | Array<string>
            hintText = '',
            type = 'text',
            containerClass = '',
            inputWidthClass = 'w-full',
            bgColor = 'bg-gray-100',
            ...rest
        },
        ref,
    ) => {
        const hasErrors = checkHasErrors(errors);
        const renderErrorTexts = () => {
            if (errors instanceof Object) {
                return Object.entries(errors).map(([type, message]) => (
                    <ErrorText key={type} text={message} />
                ));
            } else if (errors instanceof Array) {
                return errors.map((text, i) => <ErrorText key={i} text={text} />);
            } else {
                return <ErrorText text={errors} />;
            }
        };

        return (
            <div className={containerClass}>
                {(label || hintText) && (
                    <div
                        className={classNames(
                            'flex',
                            hintText && !label ? 'justify-end' : 'justify-between',
                        )}>
                        <label
                            htmlFor={id}
                            className="block text-sm font-medium text-gray-700 mb-0">
                            {label}
                        </label>
                        <span className="text-sm self-end leading-5 text-gray-500">{hintText}</span>
                    </div>
                )}
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                        {...rest}
                        id={id}
                        className={classNames(
                            hasErrors
                                ? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 text-red-900 pr-10'
                                : 'focus:ring-blue-500 focus:border-blue-500 border-gray-300',
                            'block sm:text-sm rounded-md',
                            inputWidthClass,
                            className,
                            bgColor
                        )}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onChange={onChange}
                        aria-invalid={hasErrors}
                        aria-describedby={`${id}-error`}
                        ref={ref}
                    />
                    {hasErrors && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <svg
                                className="h-5 w-5 text-red-500"
                                fill="currentColor"
                                viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                {renderErrorTexts()}
            </div>
        );
    },
);

export default Input;
