import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectById as selectPriceById } from '../stores/prices';
import { selectById as selectSymbolById } from '../stores/symbols';
import createCachedSelector from 're-reselect';

const blankFormattedPrice = {
    lastPrice: '-',
    change: '',
    changePercent: '-',
    color: 'gray',
};

function calculateChangePercent(dayClose, lastPrice) {
    let percent;

    if (lastPrice !== 0) {
        if (dayClose !== 0) {
            percent = ((lastPrice - dayClose) / dayClose) * 100;
        } else {
            percent = lastPrice * 100;
        }
    } else {
        percent = -dayClose * 100;
    }

    return percent;
}

const formattedPriceSelector = createCachedSelector(
    selectSymbolById,
    selectPriceById,
    (symbol, price) => {
        if (!symbol || !price) return blankFormattedPrice;

        const change = price.lastPrice - price.dayClose;
        const changePercent = calculateChangePercent(price.dayClose, price.lastPrice);

        return {
            lastPrice: price.lastPrice.toFixed(symbol.decPoint),
            change: change.toFixed(symbol.decPoint),
            changePercent: changePercent.toFixed(2),
            color: changePercent === 0 ? 'gray' : changePercent > 0 ? 'green' : 'red',
        };
    },
)((state, code) => code);

const usePrice = (code, { dynamicColor = false } = {}) => {
    const symbol = useSelector(state => selectSymbolById(state, code));
    const original = useSelector(state => selectPriceById(state, code));
    const formatted = useSelector(state => formattedPriceSelector(state, code));
    const previousPrice = useRef(null);
    const timeout = useRef(null);
    const [color, setColor] = useState(null);

    useEffect(() => {
        if (dynamicColor) {
            clearTimeout(timeout.current);
            setColor(
                previousPrice.current && previousPrice.current !== '-'
                    ? previousPrice.current > formatted.lastPrice
                        ? 'red'
                        : 'green'
                    : null,
            );

            timeout.current = setTimeout(() => {
                setColor(null);
            }, 500);

            previousPrice.current = formatted.lastPrice;

            return () => {
                clearTimeout(timeout.current);
            };
        }
    }, [formatted.lastPrice, dynamicColor]);

    return { original, formatted, symbol, color };
};

export default usePrice;
