// @flow

import React, { forwardRef } from 'react';
import classNames from 'classnames';

const ErrorText = ({ text }) => (
    <p role="alert" className="mt-2 text-sm text-red-600">
        {text}
    </p>
);

export const checkHasErrors = errors => {
    if (
        (errors instanceof Object && Object.entries(errors).length > 0) ||
        (errors instanceof Array && errors.length > 0) ||
        (errors instanceof String && errors.length > 0)
    ) {
        return true;
    }

    return false;
};

const SelectBox = (
    {
        value,
        onChange,
        options,
        selectedOption,
        label,
        placeholder,
        loading,
        disabled,
        id,
        errors = [], // string | Object<{[errorType: string]: string}> | Array<string>
        hintText = '',
        containerClass = '',
        optionLabelGetter = 'label',
        optionValueGetter = 'value',
        bgColor = 'bg-gray-100',
        ...rest
    },
    ref,
) => {
    const hasErrors = checkHasErrors(errors);
    const getOptionLabel = option => {
        if (!option) {
            return undefined;
        }

        if (typeof optionLabelGetter === 'function') {
            return optionLabelGetter(option);
        }

        return option[optionLabelGetter];
    };
    const getOptionValue = option => {
        if (!option) {
            return undefined;
        }

        if (typeof optionValueGetter === 'function') {
            return optionValueGetter(option);
        }

        return option[optionValueGetter];
    };

    const renderErrorTexts = () => {
        if (errors instanceof Object) {
            return Object.entries(errors).map(([type, message]) => (
                <ErrorText key={type} text={message} />
            ));
        } else if (errors instanceof Array) {
            return errors.map((text, i) => <ErrorText key={i} text={text} />);
        } else if (errors instanceof String) {
            return <ErrorText text={errors} />;
        }

        return null;
    };

    return (
        <div className={containerClass}>
            {label && (
                <div>
                    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                    <span className="text-sm self-end text-gray-500">{hintText}</span>
                </div>
            )}
            <div className={`rounded-md shadow-sm ${label && 'mt-1'}`}>
                <select
                    {...rest}
                    disabled={disabled}
                    value={onChange ? getOptionValue(selectedOption) ?? value ?? '' : undefined}
                    onChange={e => {
                        if (onChange instanceof Function) {
                            const selectedOptionIndex = options.findIndex(
                                // eslint-disable-next-line eqeqeq
                                option => getOptionValue(option) == e.target.value,
                            );

                            onChange(options[selectedOptionIndex], selectedOptionIndex);
                        }
                    }}
                    ref={ref}
                    id={id}
                    className={classNames(
                        'block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm rounded-md',
                        typeof placeholder === 'string' &&
                            !value &&
                            !getOptionValue(selectedOption) &&
                            'text-gray-500',
                        hasErrors
                            ? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500 text-red-900'
                            : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500',
                        bgColor,
                    )}>
                    {placeholder && (
                        <option value="" disabled>
                            {placeholder}
                        </option>
                    )}
                    {options.map(option => (
                        <option key={getOptionValue(option)} value={getOptionValue(option)}>
                            {getOptionLabel(option)}
                        </option>
                    ))}
                </select>
            </div>
            {renderErrorTexts()}
        </div>
    );
};

// $FlowFixMe
export default forwardRef(SelectBox);
