import React from 'react';
import HeaderTicker from './HeaderTicker';
import Menu from './Menu';
import HeaderDelayedDataAlert from './HeaderDelayedDataAlert';
import { useLocation } from 'react-router-dom';
import { useLicence } from '../hooks';

export default () => {
    const realtimePriceLicence = useLicence('realtime_prices');
    const { pathname } = useLocation(); // TODO: Ayrı layout daha doğru olabilir

    return (
        <div>
            <HeaderTicker />
            <Menu />
            {pathname !== '/tanitim' && !realtimePriceLicence && <HeaderDelayedDataAlert />}
        </div>
    );
};
