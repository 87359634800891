// @flow

import React, { useMemo } from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

import Transition from './Transition';

const menuItemTextStyle = {
    default: {
        activeText: 'bg-gray-100 text-gray-900',
        passiveText: 'text-gray-700',
        activeSvg: 'text-gray-500',
        passiveSvg: 'text-gray-400',
    },
    danger: {
        activeText: 'bg-gray-100 text-red-900',
        passiveText: 'text-red-700',
        activeSvg: 'text-red-500',
        passiveSvg: 'text-red-400',
    },
};

const dropdownOrigins = {
    right: 'origin-top-right right-0',
    left: 'origin-top-left left-0',
};

type MenuItems = Array<
    Array<{
        onClick: Function,
        label: string,
        SvgComponent?: string,
        disabled?: boolean,
        style?: 'default' | 'danger',
    }>,
>;

type Params = {
    label?: string,
    dropdownOrigin?: $Keys<typeof dropdownOrigins>,
    menuItems: MenuItems,
    ButtonComponent?: React$StatelessFunctionalComponent<any>,
    HeaderComponent?: React$Node,
    disabled?: boolean,
};

const DefaultButton = ({ label, disabled }) => {
    return (
        <Menu.Button
            disabled={disabled}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-500">
            {label}
            <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </Menu.Button>
    );
};

const Dropdown = ({
    label = 'İşlemler',
    menuItems = [],
    dropdownOrigin = 'left',
    ButtonComponent = DefaultButton,
    HeaderComponent,
    disabled = false,
}: Params) => {
    const renderMenuItems = useMemo(() => {
        return menuItems.map((menuItemsGroup, i) => (
            <div key={i} className="py-1">
                {menuItemsGroup.map((menuItem, i) => (
                    <Menu.Item key={`menuitem-${i}`} disabled={menuItem.disabled ?? false}>
                        {({ active, disabled }) => {
                            const menuItemStyle = menuItem.style ?? 'default';
                            return (
                                <button
                                    onClick={menuItem.onClick}
                                    className={classNames(
                                        'group flex items-center px-4 py-2 text-sm leading-5 focus:outline-none w-full',
                                        menuItemTextStyle[menuItemStyle][
                                            active ? 'activeText' : 'passiveText'
                                        ],
                                        disabled && 'cursor-not-allowed opacity-50',
                                    )}
                                    role="menuitem">
                                    {menuItem.SvgComponent && (
                                        <menuItem.SvgComponent
                                            className="mr-3"
                                            size="h-5 w-5"
                                            color={
                                                menuItemTextStyle[menuItemStyle][
                                                    active ? 'activeSvg' : 'passiveSvg'
                                                ]
                                            }
                                        />
                                    )}
                                    {menuItem.label}
                                </button>
                            );
                        }}
                    </Menu.Item>
                ))}
            </div>
        ));
    }, [menuItems]);

    return (
        <div className="relative inline-block text-left">
            <Menu>
                {({ open }) => (
                    <>
                        <div>
                            <span className="rounded-md shadow-sm">
                                {<ButtonComponent disabled={disabled} label={label} />}
                            </span>
                        </div>

                        <Transition
                            show={open}
                            className={classNames(
                                'absolute mt-2 w-56 rounded-md shadow-lg z-50',
                                dropdownOrigins[dropdownOrigin],
                            )}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items
                                static
                                className="rounded-md bg-white ring-1 ring-black ring-opacity-5 outline-none divide-y divide-gray-100"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu">
                                {HeaderComponent}
                                {renderMenuItems}
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
};

Dropdown.Button = Menu.Button;

export default Dropdown;
export type { MenuItems };
