// for react-hook-form validations

export const required = inputLabel => `${inputLabel} alanı boş bırakılamaz.`;
export const minLength = (inputLabel, value) => ({
    value,
    message: `${inputLabel} ${value} karakterden kısa olamaz.`,
});
export const emailPattern = () => ({
    value: /\S+@\S+\.\S+/,
    message: 'Email adresi geçerli değil.',
});
