import { ArrowRightIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components';
import { useUser } from '../../hooks';

const Welcome = () => {
    const user = useUser();

    return (
        <div className="bg-white rounded shadow p-4 sm:p-10 text-center">
            <h3 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-4 mt-4">
                Fonmatik Nedir?
            </h3>
            <div>
                Fonmatik akıllı bir algoritmaya sahip olan dijital yatırım yönetim platformudur. Fon
                yatırımı kararlarınızda yardım etmek üzere geliştirilmiş Fonmatik ile kendi risk
                profilinize uygun yatırım fonlarını bulabilirsiniz.
            </div>

            <h3 className="text-xl font-extrabold mt-6 mb-2">Nasıl Kullanırım?</h3>

            <ul>
                <li className="mb-1">
                    <ArrowRightIcon className="w-4 h-4 text-blue-600 inline mr-2" />
                    Risk profilinizi analiz edebilmemiz için anketi çözün.
                </li>
                <li className="mb-1">
                    <ArrowRightIcon className="w-4 h-4 text-blue-600 inline mr-2" />
                    Profilinize uygun Fon Portföy Önerimizi görüntüleyin.
                </li>
                <li className="mb-1">
                    <ArrowRightIcon className="w-4 h-4 text-blue-600 inline mr-2" />
                    Kendinize uygun fonları ve ağırlıkları seçin.
                </li>
                <li className="mb-1">
                    <ArrowRightIcon className="w-4 h-4 text-blue-600 inline mr-2" />
                    Seçtiğiniz fon portföyünün performansını ölçün.
                </li>
            </ul>

            <div className="text-center mt-10">
                {user ? (
                    <Link to="/fonmatik/anket">
                        <Button color="green" label="Hemen Başla" size="xl" />
                    </Link>
                ) : (
                    <Link to="/giris-yap">
                        <Button
                            color="blue"
                            label="Fonmatik İçin Üye Girişi Yapmanız Gerekmektedir"
                        />
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Welcome;
