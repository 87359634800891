import api from '../../core/api';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Modal, PageHeader, toast } from '../../components';
import Pie from './Pie';
import Range from './Range';
import Steps from './Steps';
import FundList from './FundList';
import { ClipboardListIcon } from '@heroicons/react/solid';
import SelectedFunds from './SelectedFunds';

const riskProfileLabels = {
    tumu: [
        'Muhafazakar',
        'Muhafazakar',
        'Muhafazakar',
        'Muhafazakar',
        'Temkinli',
        'Temkinli',
        'Temkinli',
        'Dengeli',
        'Dengeli',
        'Dengeli',
        'Dinamik',
        'Dinamik',
        'Dinamik',
        'Atak',
        'Atak',
        'Atak',
        'Agresif',
        'Agresif',
        'Agresif',
    ],
    faizsiz: [
        'Muhafazakar',
        'Muhafazakar',
        'Muhafazakar',
        'Muhafazakar',
        'Muhafazakar',
        'Dengeli',
        'Dengeli',
        'Dengeli',
        'Dengeli',
        'Dengeli',
        'Atak',
        'Atak',
        'Atak',
        'Atak',
        'Atak',
        'Agresif',
        'Agresif',
        'Agresif',
        'Agresif',
    ],
};

const Distribution = () => {
    const { t } = useTranslation();
    const { score, interest } = useParams();
    const [showModal, setShowModal] = useState(null);
    const [data, setData] = useState(null);
    const handleModalClose = useCallback(() => setShowModal(null), []);
    const [weights, setWeights] = useState({});
    const [addedFundCodes, setAddedFundCodes] = useState([]);
    const filteredSuggestions = data?.suggestions.filter(row => row.weight > 0) ?? [];

    const handleWeightChange = (code, weight) =>
        setWeights(weights => ({ ...weights, [code]: weight }));

    const handleAdd = useCallback(fund => {
        setWeights(weights =>
            weights.hasOwnProperty(fund.code) ? weights : { ...weights, [fund.code]: '1' },
        );
        setAddedFundCodes(addedFundCodes => [...addedFundCodes, fund.code]);
    }, []);

    const handleRemove = useCallback(
        code => setAddedFundCodes(addedFundCodes => addedFundCodes.filter(c => code !== c)),
        [],
    );

    useEffect(() => {
        api.get(`funds/distribution/?score=${score}&interest=${interest === 'tumu' ? '1' : '0'}`)
            .then(res => setData(res.data))
            .catch(error =>
                toast.simple({
                    title: 'Fon dağılım önerinizi oluştururken bir hata oluştu',
                    icon: 'error',
                }),
            );
    }, [score, interest]);

    if (!data) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{t('fund.distribution')}</title>
            </Helmet>
            <PageHeader title={t('fund.distribution')} />
            <Steps activeStep={1} />

            <Modal
                position="top"
                contentClassName="bg-white m-2 w-full sm:m-8 max-w-4xl"
                contentOverflowClass="overflow-auto"
                show={showModal !== null}
                onClose={handleModalClose}>
                {showModal && (
                    <FundList
                        category={showModal}
                        suggestionIds={filteredSuggestions.map(
                            suggestion => suggestion.category.id,
                        )}
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        addedFundCodes={addedFundCodes}
                        onClose={handleModalClose}
                    />
                )}
            </Modal>

            <Range value={Math.floor((score / 18) * 100)} />
            <div className="mt-6 mb-3 rounded-md shadow-md bg-blue-600 text-white p-4">
                <strong>Risk Profiliniz:</strong> {riskProfileLabels[interest][score]}
            </div>
            <div className="flex rounded-md shadow-md bg-gray-50">
                <div className="flex-1">
                    <div className="py-4 bg-white border-b font-medium text-lg text-center">
                        Fon Dağılım Önerisi
                    </div>
                    <div className="h-80">
                        <Pie data={filteredSuggestions} />
                    </div>
                </div>
                <div className="flex-1 border-l">
                    <div className="py-4 bg-white border-b font-medium text-lg text-center">
                        Seçtiğiniz Fonlar
                    </div>
                    {addedFundCodes.length === 0 ? (
                        <div className="px-6 py-4">
                            <div className="mt-2 text-sm text-center p-4 rounded-md shadow-md bg-white">
                                <ClipboardListIcon className="inline h-16 w-16 mb-4 text-gray-300" />
                                <div className="text-gray-500">
                                    Aşağıdaki fon kategorilerinden istediğiniz fonları ekledikçe
                                    listeniz oluşacak. Daha sonra bu listeye göre portföy
                                    performanınızı görüntüleyebilirsiniz
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SelectedFunds
                            addedFundCodes={addedFundCodes}
                            weights={weights}
                            onWeightChange={handleWeightChange}
                            onRemove={handleRemove}
                        />
                    )}
                </div>
            </div>

            <div className="mt-6 rounded-md shadow-md bg-gray-50 overflow-hidden">
                <div className="py-4 bg-white border-b font-medium text-lg text-center">
                    Yeni Fon Ekle
                </div>
                {data.categories.map(cat => (
                    <div key={cat.id} className="hover:bg-white p-4 border-b">
                        <div className="text-md font-medium flex items-center">
                            <div className="flex-grow">
                                {cat.title}{' '}
                                {data.suggested_parent_category_ids.indexOf(cat.id) > -1 && (
                                    <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                        Önerilen
                                    </span>
                                )}
                            </div>
                            <button
                                onClick={() => setShowModal(cat)}
                                className="border border-green-600 rounded-lg text-sm py-0.5 px-2 text-green-600 font-medium">
                                Ekle
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Distribution;
