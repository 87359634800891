import React, { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';

const colors = ['hsl(248, 70%, 50%)', 'hsl(162, 70%, 50%)', 'hsl(5, 70%, 50%)', 'hsl(0, 70%, 50%)'];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const Pie = ({ data }) => {
    const chartData = useMemo(
        () =>
            data.map((row, i) => ({
                id: row.category.title
                    .split(' ')
                    .map(x => x[0])
                    .join('')
                    .toUpperCase(),
                label: row.category.title,
                value: Math.round(row.weight * 100),
                color: colors[i],
            })),
        [data],
    );

    return (
        <ResponsivePie
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
        />
    );
};

export default Pie;
