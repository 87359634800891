// @flow

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { QuickViewer, PageHeader } from '../../components';
import { selectByPrefix } from '../../stores/symbols';

const description = `Canlı Döviz sayfamızda farklı para birimlerinin birbirlerine karşı değer artışlarını ve değişimlerini anlık olarak takip edebilirsiniz. Burada USDTRY, EURTRY, EURUSD, GBPUSD gibi paritelerin fiyatlarını görüntüleyebilirsiniz.  Dolar kaç lira oldu anlık takip etmek için Borsa Ekranım’a girebilirsiniz.

Anlık olarak takip ettiğiniz fiyat ve değişim verilerine ek olarak grafikte de gün içerisindeki hareketi izleyebilirsiniz. İncelemek istediğiniz para birimi çiftinin sayfasında teknik analiz yapabilir, açılış, kapanış, gün içi en yüksek, en düşük gibi değerleri bulabilirsiniz. Ayrıca paritenin geçmiş dönem değerlerine ulaşabilirsiniz.`;

const Currency = () => {
    const { t } = useTranslation();
    const symbols = useSelector(state => selectByPrefix(state, 'FX'));
    const symbolIds = useMemo(() => symbols.map(symbol => symbol.id), [symbols]);

    return (
        <>
            <Helmet>
                <title>Canlı Döviz Kuru Fiyatları, Güncel Döviz Alış ve Satış Fiyatları</title>
                <meta
                    name="description"
                    content="Canlı Döviz sayfamızda farklı para birimlerinin birbirlerine karşı değer artışlarını ve değişimlerini anlık olarak takip edebilirsiniz. "
                />
            </Helmet>

            <PageHeader title="Canlı Döviz Kurları" />

            <div className="bg-white rounded-lg shadow mt-4">
                <QuickViewer codes={symbolIds} />
            </div>

            <div className="bg-white mt-4 w-full rounded-lg shadow px-4 pt-4 pb-2">
                {description.split('\n').map((paragraph, i) => (
                    <p className="mb-2" key={`${i}`}>
                        {paragraph}
                    </p>
                ))}
            </div>
        </>
    );
};

export default Currency;
