import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import logo from '../../assets/borsaekranim-logo.png';
import { useRequest } from '../../hooks';
import {
    Input,
    Checkbox,
    Button,
    SelectBox,
    ErrorAlert,
    PhoneInput,
    Textarea,
} from '../../components';
import { required, minLength, emailPattern } from '../../utils/validation';
import { isMobile } from '../../utils/helpers';
import ilIlceData from '../../data/il_ilce.json';

const Register = () => {
    const { register, handleSubmit, getValues, control, errors } = useForm({ criteriaMode: 'all' });
    const [{ loading, errors: apiErrors }, registerRequest] = useRequest({
        method: 'post',
        url: '/auth/register/',
    });
    const history = useHistory();

    const onSubmit = data => {
        registerRequest(data, res => history.push({ pathname: '/sms-dogrulama', state: { data } }));
    };

    return (
        <>
            <div>
                <Link to="/" title="Borsa Ekranım">
                    <img className="h-10 w-auto" src={logo} alt="Borsa Ekranım" />
                </Link>
                <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                    Ücretsiz Üye Olun
                </h2>
                <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                    Hesabınız var mı?{' '}
                    <Link
                        className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                        to="/giris-yap">
                        Giriş yapın
                    </Link>
                </p>
            </div>

            <div className="mt-8">
                <div className="mt-6">
                    <ErrorAlert className="mb-8" text={apiErrors.detail} />
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex space-x-4">
                            <Input
                                id="first_name"
                                name="first_name"
                                label="Ad"
                                autoComplete="given-name"
                                autoFocus={!isMobile}
                                containerClass="w-1/2"
                                errors={errors.first_name?.types || apiErrors.first_name}
                                ref={register({
                                    required: required('Ad'),
                                })}
                            />
                            <Input
                                id="last_name"
                                name="last_name"
                                label="Soyad"
                                autoComplete="family-name"
                                containerClass="w-1/2"
                                errors={errors.last_name?.types || apiErrors.last_name}
                                ref={register({
                                    required: required('Soyad'),
                                })}
                            />
                        </div>
                        <Input
                            id="email"
                            name="email"
                            label="Email adresi"
                            containerClass="mt-6"
                            type="email"
                            autoComplete="email"
                            errors={errors.email?.types || apiErrors.email}
                            ref={register({
                                required: required('Email adresi'),
                                pattern: emailPattern(),
                            })}
                        />

                        <Controller
                            defaultValue=""
                            rules={{
                                required: required('Telefon'),
                                minLength: minLength('Telefon numarası', 10),
                            }}
                            name="phone"
                            errors={errors.phone?.types || apiErrors.phone}
                            control={control}
                            as={<PhoneInput className="mt-6" errors={errors} />}
                        />

                        <Textarea
                            ref={register({
                                required: required('Adres'),
                            })}
                            containerClass="mt-6"
                            id="street_address"
                            name="street_address"
                            errors={errors.street_address?.types || apiErrors.street_address}
                            label="Adres"
                        />

                        <SelectBox
                            id="city"
                            label="Şehir"
                            name="city"
                            optionLabelGetter="title"
                            optionValueGetter="title"
                            placeholder="Şehir seçimi yapınız"
                            containerClass="mt-6"
                            options={ilIlceData}
                            errors={errors.city?.types || apiErrors.city}
                            defaultValue=""
                            ref={register({
                                required: required('Şehir'),
                            })}
                        />

                        <div className="flex space-x-4 mt-6">
                            <Input
                                id="password"
                                name="password"
                                label="Şifre"
                                type="password"
                                autoComplete="new-password"
                                errors={errors.password?.types || apiErrors.password}
                                ref={register({
                                    required: required('Şifre'),
                                    minLength: minLength('Şifre', 6),
                                })}
                            />
                            <Input
                                id="passwordAgain"
                                name="passwordAgain"
                                label="Şifre (tekrar)"
                                type="password"
                                autoComplete="new-password"
                                errors={errors.passwordAgain?.types}
                                ref={register({
                                    validate: value => {
                                        if (value === getValues()['password']) {
                                            return true;
                                        } else {
                                            return 'Şifre alanları birbiriyle uyuşmuyor.';
                                        }
                                    },
                                })}
                            />
                        </div>

                        <Checkbox
                            id="kvkk"
                            name="kvkk"
                            containerClass="mt-6"
                            errors={errors.kvkk?.types}
                            ref={register({
                                required: required('Kullanım ve Gizlilik Şartları'),
                            })}
                            label={
                                <>
                                    <Link
                                        to="/kvkk"
                                        target="_blank"
                                        className={
                                            'font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150'
                                        }>
                                        KVKK Aydınlatma Metni
                                    </Link>
                                    'ni okudum ve kabul ediyorum.
                                </>
                            }
                        />

                        <Checkbox
                            id="marketing"
                            name="marketing"
                            containerClass="mt-6"
                            errors={errors.marketing?.types}
                            label="Elektronik İletişim İzni"
                        />

                        <div className="mt-6">
                            <Button
                                label="Onayla"
                                loading={loading}
                                type="submit"
                                className="w-full"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Register;
