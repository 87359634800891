import React from 'react';
import { Helmet } from 'react-helmet';

import Overview from './Overview';
import Calendar from './Calendar';
import News from './News';

const Home = () => (
    <>
        <Helmet titleTemplate="%s">
            <title>Borsa Ekranım</title>
            <meta
                name="description"
                content="Borsa Ekranım ile borsayı canlı olarak takip edebilirsiniz. Ayrıca; getiri analizi, ekonomik takvim, haberler ve teknik analiz grafikleri gibi özelliklerden de yararlanabilirsiniz."
            />

            <meta property="og:type" content="website" />
        </Helmet>

        <Overview />
        <div className="mt-4">
            <Calendar />
        </div>

        <div className="mt-4">
            <News />
        </div>
        {/*Getiri analizi*/}

        {/* İçime sinmedi
        <div className="my-6">
            <Videos />
        </div>*/}
    </>
);

export default Home;
