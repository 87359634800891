import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../components';
import { usePrice } from '../../hooks';
import { selectByPrefix } from '../../stores/symbols';
import { getSymbolPath } from '../../routes/helpers';

const selectSymbols = createSelector(selectByPrefix, symbols =>
    symbols.filter(symbol => symbol.id.length < 6),
);

const Row = ({ code }) => {
    const { formatted, symbol, color } = usePrice(code, { dynamicColor: true });

    return (
        <Link
            to={getSymbolPath(symbol)}
            title={`${code} - ${symbol.title} Fiyat, Grafik, Analiz ve Haberler`}
            className={`flex flex-row border-t border-gray-300 hover:bg-gray-200 py-2 px-3 bg-${color}-100`}>
            <div className={`flex flex-1 text-${color}-900 font-medium`}>{code}</div>
            <div className={`flex flex-1 text-${color}-900`}>₺{formatted.lastPrice}</div>
            <div className={`flex flex-1 text-end text-${formatted.color}-500`}>
                {formatted.changePercent}%
            </div>
        </Link>
    );
};

const GridHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="border-b border-gray-300 flex flex-row py-2 px-3 font-semibold">
            <div className="flex flex-1">{t('general.symbol')}</div>
            <div className="flex flex-1">{t('general.price')}</div>
            <div className="flex flex-1">{t('symbol.change')}</div>
        </div>
    );
};

const Overview = () => {
    const symbols = useSelector(state => selectSymbols(state, 'IMKBH'));

    return (
        <div className="rounded-lg bg-white shadow">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5">
                <div className="col-start-1">
                    <GridHeader />
                </div>
                <div className="col-start-2 hidden sm:block">
                    <GridHeader />
                </div>
                <div className="col-start-3 hidden lg:block">
                    <GridHeader />
                </div>

                {symbols.map(symbol => (
                    <Row key={symbol.id} code={symbol.id} />
                ))}
            </div>
        </div>
    );
};

const description = `Canlı Borsa sayfamız sayesinde Borsa İstanbul’da işlem gören hisse senedi fiyatlarını ücretsiz şekilde anlık olarak izleyebilirsiniz. Ayrıca sayfamız üzerinden borsa endekslerini de işlem saatleri içerisinde canlı takip edebilirsiniz. BIST100, BIST50, BIST30, Bankacılık Endeksi, Sanayi Endeksi ve diğer endeks verilerine ulaşabilirsiniz. 

Canlı şekilde izlediğiniz verilere ek olarak anında güncellenen grafikler ile de takibinizi yapabilirsiniz. Borsaekranım üzerinde takip ettiğiniz hisse senedi ve endekslere yönelik detaylı bilgileri bulabilirsiniz. Şirketlere ilişkin özet finansal tablolara, işlem hacimlerine, rasyolara ve farklı periyotlardaki getiri detaylarına ulaşabilirsiniz.`;

const LiveStocks = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Canlı Borsa Ekranı</title>
                <meta
                    name="description"
                    content="Canlı Borsa sayfamız sayesinde Borsa İstanbul’da işlem gören hisse senedi fiyatlarını ücretsiz şekilde anlık olarak izleyebilirsiniz."
                />
            </Helmet>

            <div className="flex mx-auto">
                <div className="flex-grow w-full">
                    <PageHeader title={t('module.liveStocks')} />
                    <Overview />
                    <div className="bg-white mt-4 w-full rounded-lg shadow px-4 pt-4 pb-2">
                        {description.split('\n').map((paragraph, i) => (
                            <p className="mb-2" key={`${i}`}>
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LiveStocks;
