import React from 'react';
import { toast, cssTransition } from 'react-toastify';
import classNames from 'classnames';

const getIcon = (icon, className) => {
    switch (icon) {
        case 'success':
            className = className ?? 'text-green-400';
            return (
                <svg
                    className={classNames('h-6 w-6', className)}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            );
        case 'error':
            className = className ?? 'text-red-400';
            return (
                <svg
                    className={classNames('h-6 w-6', className)}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'ban':
            className = className ?? 'text-red-400';
            return (
                <svg
                    className={classNames('h-6 w-6', className)}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                </svg>
            );
        default:
            break;
    }
};

const simple = options => {
    const { title = '', description, icon, iconClass, ...props } = options ?? {};
    return toast(
        ({ closeToast }) => (
            <div className="max-w-sm w-full bg-white shadow-lg cursor-pointer rounded-lg pointer-events-auto">
                <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-2 sm:p-4">
                        <div className="flex items-start">
                            {icon && (
                                <div className="flex-shrink-0">{getIcon(icon, iconClass)}</div>
                            )}
                            <div className={classNames('w-0 flex-1 pt-0.5', icon && 'ml-3')}>
                                <p className="text-xs sm:text-sm leading-5 font-medium text-gray-900">
                                    {title}
                                </p>
                                {description && (
                                    <p className="mt-1 text-xs sm:text-sm leading-5 text-gray-500">
                                        {description}
                                    </p>
                                )}
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    onClick={() => closeToast()}
                                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ),
        {
            closeButton: false,
            transition: AlertTransition,
            ...props,
        },
    );
};

const AlertTransition = cssTransition({
    enter: 'animate-alertIn',
    exit: 'animate-alertOut',
    duration: [300, 100],
});

const toastObj = { simple };

export default toastObj;
