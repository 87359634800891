// @flow
import React from 'react';

import { useRequest } from '../../hooks';

type Params = {
    newItem: Object,
    onClose: Function,
};

const NewDetailsModalContent = ({ onClose, newItem }: Params) => {
    const [{ response, loading }] = useRequest(`news/${newItem.id}/`);

    if (loading) {
        return (
            <div className="animate-pulse flex space-x-4 w-full">
                <div className="flex-1 space-y-8 py-1">
                    <div className="h-3 bg-gray-200 rounded w-3/4"></div>
                    <div className="space-y-2">
                        <div className="h-3 bg-gray-100 rounded"></div>
                        <div className="h-3 bg-gray-100 rounded w-5/6"></div>
                        <div className="h-3 bg-gray-100 rounded w-5/6"></div>
                        <div className="h-3 bg-gray-100 rounded w-4/6"></div>
                        <div className="h-3 bg-gray-100 rounded w-3/6"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="pb-5 flex justify-between border-b border-gray-200 relative">
                <div
                    className="text-lg pr-10 leading-6 font-medium text-gray-900"
                    id="modal-headline">
                    {newItem.title}
                </div>
                <div className="absolute right-0 h-full">
                    <button
                        onClick={onClose}
                        type="button"
                        className="flex h-full justify-end w-10 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Close">
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div>
                <p className="text-sm pt-5 leading-5 text-gray-500 whitespace-pre-wrap">
                    {response.text}
                </p>
            </div>
        </>
    );
};

export default NewDetailsModalContent;
