import React from 'react';

import { usePrice } from '../../hooks';

const HeadStats = ({ data, code }) => {
    const { formatted } = usePrice(code);

    return (
        <div className="grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
            <div>
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-base leading-6 font-normal text-gray-900">
                            Son İşlem Fiyatı
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900 tabular-nums">
                                ₺{formatted.lastPrice}
                            </div>
                            <div
                                className={`inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-${formatted.color}-100 text-${formatted.color}-800 md:mt-2 lg:mt-0`}>
                                <svg
                                    className={`-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-${formatted.color}-500`}
                                    fill="currentColor"
                                    viewBox="0 0 20 20">
                                    {formatted.changePercent > 0 ? (
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                        />
                                    ) : (
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                        />
                                    )}
                                </svg>
                                <span className="sr-only">Yüzdelik değişim</span>
                                <span className="tabular-nums">{formatted.changePercent}</span>%
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-base leading-6 font-normal text-gray-900">
                            {data.second_metric?.title}
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                                {data.second_metric?.value}
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                        <dt className="text-base leading-6 font-normal text-gray-900">
                            {data.third_metric?.title}
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                                {data.third_metric?.value}
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default HeadStats;
