import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

import { toast, ScrollToTop } from './components';
import api, { setTokenToApis } from './core/api';
import { userReceived } from './stores/auth';
import AppRoutes from './routes';
import './tailwind.generated.css';
import 'react-toastify/dist/ReactToastify.minimal.css';
import { init as initPrices } from './stores/prices';
import { init as initLists } from './stores/lists';

const App = () => {
    const [ready, setReady] = useState(false);
    const token = useSelector(state => state.auth?.tokens?.access);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            setTokenToApis(token);
            api.get('/auth/check/')
                .then(response => {
                    dispatch(userReceived(response.data.data));

                    const hasRealtimeLicence =
                        response.data.data.profile.active_licences.indexOf('realtime_prices') > -1;

                    dispatch(initPrices(hasRealtimeLicence));
                    dispatch(initLists(hasRealtimeLicence));
                })
                .catch(error =>
                    toast.simple({
                        title: 'Kullanıcı verileriniz çekilirken hata oluştu',
                        description: error.data?.detail,
                        icon: 'error',
                    }),
                );
        } else {
            dispatch(initPrices(false));
            dispatch(initLists(false));
        }

        setReady(true);
    }, [token, dispatch]);

    if (!ready) {
        // TODO: Daha iyi olabilir (olmalı?)
        // Loading göstermeye değer mi bilemedim
        return null;
    }

    return (
        <>
            <Helmet titleTemplate="%s">
                <meta property="fb:app_id" content="393509572290890" />
            </Helmet>

            <ScrollToTop />
            <AppRoutes />
            <ToastContainer
                className="fixed inset-0 flex flex-col pointer-events-none sm:px-6 sm:pb-6 sm:pt-3"
                toastClassName="w-full flex mb-2 sm:mb-0 sm:mt-3"
                bodyClassName="w-full flex items-end justify-center sm:items-start sm:justify-end"
            />
        </>
    );
};

export default App;
