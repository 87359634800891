import React from 'react';
import { Link } from 'react-router-dom';

import { CalendarTable, SectionHeader } from '../../components';

const Calendar = () => (
    <div className="bg-white rounded-lg shadow">
        <SectionHeader title="Ekonomik Takvim" />

        <CalendarTable filter="today" limit={10} />

        <Link
            className="px-6 border-t border-gray-200 text-blue-600 w-full font-medium py-4 inline-block"
            to="/ekonomik-takvim">
            Tüm takvimi görüntüle &rarr;
        </Link>
    </div>
);

export default Calendar;
