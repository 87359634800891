import React from 'react';
import classNames from 'classnames';

type Params = {
    title: string,
    description: string,
    onClose: Function,
    noBorder: boolean,
};

const Header = ({ title, description, onClose, noBorder }: Params) => {
    return (
        <div className={classNames(noBorder ? '' : 'border-b border-gray-200')}>
            <div className="flex justify-between relative pb-4">
                <div className="flex-1">
                    <h3
                        className="capitalize text-lg pr-10 leading-6 font-medium text-gray-900"
                        id="modal-headline">
                        {title}
                    </h3>
                    <p className="mt-1 max-w-xl text-sm text-gray-500">{description}</p>
                </div>
                <div className="absolute right-0 h-full">
                    <button
                        onClick={onClose}
                        type="button"
                        className="flex h-full justify-end w-10 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Close">
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Header;
