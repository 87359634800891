import React from 'react';
import { Link } from 'react-router-dom';

import { getSymbolPath } from '../../routes/helpers';
import usePrice from '../../hooks/usePrice';

const Col = ({ code }) => {
    const { formatted, symbol, color } = usePrice(code, { dynamicColor: true });

    return (
        <Link
            to={getSymbolPath(symbol)}
            disabled
            className="px-5 py-2 block hover:bg-gray-800 focus:outline-none">
            <div className="flex text-sm">
                <div>{code}</div>
                <div className={`flex-grow text-right text-${color}-500 tabular-nums`}>
                    {formatted.lastPrice}
                </div>
            </div>
            <div className={`mt-1 text-${formatted.color}-500`}>
                <span className="text-xl tabular-nums">{formatted.changePercent}%</span>
                <span className="text-sm ml-3 tabular-nums">{formatted.change}</span>
            </div>
        </Link>
    );
};

export default Col;
