// @flow

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';

import api from '../core/api';

type Symbol = { id: string, prefix: string, decPoint: number };

const symbolsAdapter = createEntityAdapter<Symbol>();

const symbolsSlice = createSlice({
    name: 'symbols',
    initialState: symbolsAdapter.getInitialState({ loading: false }),
    reducers: {
        symbolsLoading(state, action) {
            if (state.loading === false) {
                state.loading = true;
            }
        },
        symbolsReceived(state, action) {
            if (state.loading === true) {
                symbolsAdapter.setAll(state, action.payload);
                state.loading = false;
            }
        },
    },
});

export const { symbolsLoading, symbolsReceived } = symbolsSlice.actions;
export const { selectAll, selectById } = symbolsAdapter.getSelectors(state => state.symbols);

export const selectByPrefix = createCachedSelector(
    selectAll,
    (symbols, prefix) => prefix,
    (symbols, prefix) => {
        return symbols.filter(symbol => symbol.prefix === prefix);
    },
)((state, prefix) => prefix);

export const init = () => (dispatch: $FlowFixMe) => {
    dispatch(symbolsLoading());
    api.get('symbols/').then(res => dispatch(symbolsReceived(res.data))); // Catch
};

export default symbolsSlice.reducer;
