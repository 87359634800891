import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reducer from './stores';
import { init as initSymbols } from './stores/symbols';
import initI18n from './core/i18n';

initI18n('tr'); // todo: dili dogru durust bir yerden cek

const persistConfig = {
    key: 'portalPersistRoot',
    version: 1,
    whitelist: ['auth'],
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

let middleware = getDefaultMiddleware({
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
});

if (process.env.NODE_ENV !== 'production') {
    const logger = require('redux-logger').default;
    middleware = middleware.concat(logger);
}

const store = configureStore({
    reducer: persistedReducer,
    middleware,
});

const persistor = persistStore(store);

store.dispatch(initSymbols());

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

export { persistor, store };
