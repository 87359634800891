import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import PhoneInput from './PhoneInput';
import Input from './Input';
import ModalFooter from './Modal/Footer';
import ModalHeader from './Modal/Header';
import toast from './toast';
import { required, emailPattern, minLength } from '../utils/validation';
import { useRequest, useUser } from '../hooks';

type Params = {
    onClose: () => void,
    onSuccess?: () => void,
};

const ContactUsModalContent = ({ onClose, onSuccess = () => {} }: Params) => {
    const user = useUser();
    const [{ errors: apiErrors }, submitRequest] = useRequest({
        url: '/contact/',
        method: 'post',
    });
    const { register, handleSubmit, control, errors } = useForm({
        criteriaMode: 'all',
        defaultValues: user
            ? { email: user.email, name: `${user.first_name} ${user.last_name}` }
            : {},
    });

    const onSubmit = ({ email, phone, name }) => {
        submitRequest({ email, phone, name }, res => {
            onSuccess();
            onClose();
            toast.simple({
                title: 'Başvurunuz başarıyla alınmıştır.',
                description:
                    'Müşteri temsilcilerimiz en yakın zamanda sizinle iletişime geçecektir.',
                icon: 'success',
            });
        });
    };

    return (
        <div className="px-4 py-5 sm:p-6">
            <ModalHeader
                title="Sizi Arayalım"
                onClose={onClose}
                description="Müşteri temsilcilerimiz sizinle en yakın zamanda iletişim kuracaktır."
                noBorder
            />
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4 sm:flex sm:items-start">
                    <div className="w-full">
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            errors={errors.name?.types || apiErrors.name}
                            ref={register({ required: required('İsim') })}
                            label="İsim"
                        />
                    </div>
                    <div className="w-full pl-2">
                        <Input
                            id="email"
                            name="email"
                            type="text"
                            errors={errors.email?.types || apiErrors.email}
                            ref={register({
                                required: required('Email adresi'),
                                pattern: emailPattern(),
                            })}
                            label="Email adresi"
                        />
                    </div>
                    <div className="w-full mt-3 sm:mt-0">
                        <Controller
                            defaultValue=""
                            rules={{
                                required: required('Telefon'),
                                minLength: minLength('Telefon numarası', 10),
                            }}
                            name="phone"
                            errors={errors.phone?.types || apiErrors.phone}
                            control={control}
                            as={<PhoneInput className="sm:ml-2" />}
                        />
                    </div>
                </div>
                <ModalFooter buttons={[{ label: 'Gönder', type: 'submit' }]} />
            </form>
        </div>
    );
};

export default ContactUsModalContent;
