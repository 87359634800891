// @flow

import React from 'react';
import classNames from 'classnames';

import SelectBox from './SelectBox';
import Button from './Button';
import type { buttonColorTypes } from './Button';

const TabLink = ({ active, onClick, children, tabLinkClassName }) => (
    <button
        onClick={onClick}
        className={classNames(
            'cursor-pointer whitespace-nowrap py-4 px-6 border-b-2 border-transparent font-normal text-sm focus:outline-none',
            tabLinkClassName,
            active && 'text-blue-700 border-b-2 border-blue-600',
            !active &&
                'text-gray-500 focus:text-gray-700 focus:border-gray-300 hover:border-gray-300 hover:text-gray-700',
        )}>
        {children}
    </button>
);

type Params = {
    title: string,
    description?: string,
    titleComponent?: string | React.Component,

    withTabs?: {
        tabs: Array<{ title: string }>,
        setActiveTabIndex: (number | string) => void,
        activeTabIndex: number | string,
        tabLinkClassName: string,
    },

    withFilterButtons?: {
        buttons: Array<{ title: string, value: string | number }>,
        buttonValueKey: string,
        selectedValue: string | number,
        setSelectedValue: (number | string) => void,
    },

    actionButtons?: Array<{
        title: string,
        onClick: Function,
        svg?: React$Node,
        color?: buttonColorTypes,
        Render?: React$Node | Function,
    }>,

    RightComponent: React$Node,
};

const SectionHeader = ({
    title,
    description,
    titleComponent,
    withTabs: { activeTabIndex, setActiveTabIndex, tabs, tabLinkClassName } = {},
    withFilterButtons: { buttons, setSelectedValue, selectedValue, buttonValueKey = 'value' } = {},
    actionButtons = [],
    RightComponent,
}: Params) => {
    const TitleComponent = titleComponent || 'h2';

    return (
        <>
            <div
                className={classNames(
                    'space-y-3 px-6 pt-4 sm:flex sm:justify-between sm:items-center sm:space-x-3 sm:space-y-0',
                    !tabs ? 'border-b border-gray-200 pb-4' : 'pb-4 md:pb-0',
                )}>
                <div className="flex flex-col flex-1">
                    <TitleComponent className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </TitleComponent>
                    {description && (
                        <div className="mt-2">
                            <p className="text-md leading-3 text-gray-500">{description}</p>
                        </div>
                    )}
                </div>
                {tabs && (
                    <select
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md sm:w-auto md:hidden"
                        value={activeTabIndex}
                        onChange={e => setActiveTabIndex(e.target.value)}>
                        {tabs.map((tab, i) => (
                            <option key={i} value={i}>
                                {tab.title}
                            </option>
                        ))}
                    </select>
                )}
                <div className="flex space-x-2">
                    {buttons && (
                        <div className="flex-grow">
                            <div className="sm:hidden">
                                <SelectBox
                                    aria-label="Selected tab"
                                    onChange={e => setSelectedValue(e[buttonValueKey])}
                                    value={selectedValue}
                                    optionLabelGetter="title"
                                    optionValueGetter={buttonValueKey}
                                    options={buttons}
                                />
                            </div>
                            <span className="relative z-0 shadow-sm rounded-md hidden sm:inline-flex">
                                {buttons.map((button, i) => (
                                    <button
                                        onClick={() => setSelectedValue(button[buttonValueKey])}
                                        key={i}
                                        type="button"
                                        className={classNames(
                                            'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                                            selectedValue === button[buttonValueKey] &&
                                                'bg-gray-200',
                                            i === 0 && 'rounded-l-md',
                                            i !== 0 && '-ml-px',
                                            i === buttons.length - 1 && 'rounded-r-md',
                                        )}>
                                        {button.title}
                                    </button>
                                ))}
                            </span>
                        </div>
                    )}
                    {actionButtons.map(({ svg, onClick, title, color, Render, ...rest }) => {
                        if (Render) {
                            return Render(() => (
                                <Button
                                    onClick={onClick}
                                    svg={svg ? svg : null}
                                    label={title}
                                    color={color}
                                    key={title}
                                    {...rest}
                                />
                            ));
                        } else {
                            return (
                                <Button
                                    onClick={onClick}
                                    svg={svg ? svg : null}
                                    label={title}
                                    color={color}
                                    key={title}
                                    {...rest}
                                />
                            );
                        }
                    })}
                    {RightComponent && RightComponent}
                </div>
            </div>
            {tabs && (
                <div className="border-b border-gray-200">
                    <nav className="-mb-px hidden md:flex overflow-x-auto">
                        {tabs.map((tab, i) => (
                            <TabLink
                                key={tab.title}
                                onClick={() => setActiveTabIndex(i)}
                                // eslint-disable-next-line eqeqeq
                                active={activeTabIndex == i}
                                tabLinkClassName={tabLinkClassName}>
                                {tab.title}
                            </TabLink>
                        ))}
                    </nav>
                </div>
            )}
        </>
    );
};

export default SectionHeader;
