// @flow

import { persistor, store } from '../index';
import { tokensReceived } from '../stores/auth';
import { setTokenToApis } from '../core/api';

export function logout() {
    persistor.purge().then(() => {
        // to reset all loaded data
        window.location.reload(false);
    });
}

export function login({ access, refresh }: { access: string, refresh: string }) {
    const dispatch = store.dispatch;

    setTokenToApis(access);
    dispatch(tokensReceived({ access, refresh }));
}
