import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { checkHasErrors } from './Input';

const Checkbox = forwardRef(
    (
        {
            value,
            label,
            id,
            errors = [], // string | Object<{[errorType: string]: string}> | Array<string>
            containerClass = '',
            ...rest
        },
        ref,
    ) => {
        const hasErrors = checkHasErrors(errors);

        return (
            <div className={classNames('relative flex items-start', containerClass)}>
                <div class="flex items-center h-5">
                    <input
                        ref={ref}
                        id={id}
                        type="checkbox"
                        className={classNames(
                            hasErrors && 'border-red-500 focus:border-red-500 focus:ring-red-500',
                            'focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded',
                        )}
                        {...rest}
                    />
                </div>
                <div class="ml-3 text-sm">
                    <label
                        htmlFor={id}
                        className={classNames(
                            hasErrors ? 'text-red-900' : 'text-gray-700',
                            'font-medium',
                        )}>
                        {label}
                    </label>
                </div>
            </div>
        );
    },
);

export default Checkbox;
