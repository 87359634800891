import React from 'react';
import { useLocation } from 'react-router-dom';

import FooterCta from './FooterCta';
import { Link } from 'react-router-dom';
import { useUser } from '../hooks';

const Footer = () => {
    const user = useUser();
    const { pathname } = useLocation(); // TODO: Ayrı layout daha doğru olabilir

    return (
        <div className="bg-white mt-6">
            {!user && pathname !== '/tanitim' && <FooterCta />}

            <div className="max-w-8xl mx-auto px-4 sm:px-6 py-4">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="xl:col-span-1">
                        <p className="text-gray-500 text-base leading-6">
                            Borsa Ekranım bir İnfo Yatırım markasıdır.
                        </p>
                        <div className="mt-8 text-gray-500 text-base leading-6">
                            <div>
                                <strong>Yasal Uyarı</strong>
                            </div>
                            <div className="text-sm">
                                BİST Verileri iDeal Data tarafından 15 dakika gecikmeli olarak
                                sağlanmaktadır. Piyasalarda oluşan tüm verilere ait telif hakları
                                tamamen BIST’e ait olup, bu veriler tekrar yayınlanamaz.
                            </div>
                        </div>
                        {/* <div className="mt-8 flex">
                            <a href="/" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Instagram</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        fillRule="evenodd"
                                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                            <a href="/" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                            </a>
                        </div> */}
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                    Canlı Borsa
                                </h4>
                                <ul className="mt-4">
                                    <li>
                                        <Link
                                            to="/canli-borsa"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Hisse
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                    Serbest Piyasa
                                </h4>
                                <ul className="mt-4">
                                    <li>
                                        <Link
                                            to="/doviz"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Döviz Kurları
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link
                                            to="/altin"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Altın
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                    Analiz
                                </h4>
                                <ul className="mt-4">
                                    {/* <li className="mt-4">
                                        <Link
                                            to="/analiz"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Getiri Analizi
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Bültenler
                                        </a>
                                    </li>
                                    <li className="mt-4">
                                        <a
                                            href="/"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Aracı Kurum Raporları
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                    Kurumsal
                                </h4>
                                <ul className="mt-4">
                                    {/* <li>
                                        <a
                                            href="/"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Nasıl hesap açarım?
                                        </a>
                                    </li>
                                    <li className="mt-4">
                                        <a
                                            href="/"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Komisyon oranları
                                        </a>
                                    </li> */}
                                    <li className="mt-4">
                                        <Link
                                            to="/hakkimizda"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            Hakkımızda
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link
                                            to="/kvkk"
                                            className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                            KVKK Aydınlatma Metni
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base leading-6 text-gray-400 xl:text-center">
                        &copy; 2021 Info Yatırım. Tüm hakları saklıdır.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
