/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import { useState, useCallback, useLayoutEffect, useRef, useMemo } from 'react';
import axios from 'axios';

import api from '../core/api';

type Error = { detail?: string, [key: string]: string };

type Params = {
    manual: ?boolean,
    initialResponse?: any,
    prepareResponse: any => any,
};

const INITIAL_RESPONSE = [];
const defaultPrepareResponse = data => data;

const getConfig = (config: Object | string): Object => {
    if (typeof config === 'string') {
        return { url: config };
    }

    return config;
};

function useRequest(
    config: string | Object, // url for GET requests | axios config
    {
        manual,
        initialResponse = INITIAL_RESPONSE,
        prepareResponse = defaultPrepareResponse,
    }: Params = {},
): any {
    const configObject = useMemo(() => getConfig(config), [JSON.stringify(config)]);
    const [response, setResponse] = useState<any>(initialResponse);
    const [errors, setErrors] = useState<Error>({});
    const [loading, setLoading] = useState<boolean>(false);
    const cancelSourceRef = useRef();
    const isManual =
        manual ?? (!configObject.method || configObject.method.toLowerCase() === 'get')
            ? false
            : true;

    const cancelCurrentRequest = useCallback(() => {
        if (cancelSourceRef.current) {
            cancelSourceRef.current.cancel();
        }
    }, []);

    const withCancelToken = useCallback((config: Object) => {
        cancelCurrentRequest();

        cancelSourceRef.current = axios.CancelToken.source();

        config.cancelToken = cancelSourceRef.current.token;

        return config;
    }, []);

    const fetchData = useCallback(
        (
            data: ?Object,
            successCallback: (response: any) => void = () => {},
            errorCallback: (error: any) => void = () => {},
        ): Promise<any> => {
            if (data) {
                configObject.data = data;
            }

            setErrors({});
            setLoading(true);

            return api(withCancelToken(configObject))
                .then(res => {
                    setResponse(prepareResponse(res.data));
                    setLoading(false);
                    successCallback(res);

                    return res;
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        if (error?.data) {
                            setErrors(error.data);
                        }

                        errorCallback(error);
                        setLoading(false);
                    }
                });
        },
        [configObject, initialResponse, prepareResponse],
    );

    useLayoutEffect(() => {
        if (!isManual) {
            fetchData();
        }

        return () => {
            cancelCurrentRequest();
        };
    }, [withCancelToken, isManual, configObject, cancelCurrentRequest]);

    return [{ response, errors, loading }, fetchData];
}

export default useRequest;
