import React, { useMemo } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const toFloat = val => parseFloat(val.replace(/,/g, '.')) || 0;

const SelectedFunds = ({ addedFundCodes, onRemove, onWeightChange, weights }) => {
    const totalWeight = useMemo(
        () => addedFundCodes.reduce((cum, code) => cum + (toFloat(weights[code]) || 0), 0),
        [addedFundCodes, weights],
    );

    const url = useMemo(
        () =>
            '/fonmatik/performans/' +
            addedFundCodes.map(code => `${code}:${weights[code]}`).join(','),
        [addedFundCodes, weights],
    );

    return (
        <div className="bg-white">
            <table className="w-full">
                <thead>
                    <tr className="border-b">
                        <th className="w-12" />
                        <th className="py-2 font-medium text-left text-gray-500">Fon Kodu</th>
                        <th />
                        <th className="py-2 pr-2 font-medium text-gray-500 w-20">Ağırlık</th>
                        <th className="w-7" />
                    </tr>
                </thead>
                <tbody>
                    {addedFundCodes.map((code, i) => (
                        <tr className="border-b" key={code}>
                            <td className="py-2 text-center">
                                <span className="font-medium rounded-full bg-gray-200 text-gray-400 w-8 text-center inline-block">
                                    {i + 1}
                                </span>
                            </td>
                            <td className="py-2 pl-1 text-lg font-medium text-left">{code}</td>
                            <th className="text-right text-gray-400 font-normal pr-2">
                                {((toFloat(weights[code]) / totalWeight) * 100).toFixed(0)}%
                            </th>
                            <td className="py-2 pr-2">
                                <input
                                    className="w-20 py-1 form-input text-center border-2 rounded-md"
                                    value={weights[code]}
                                    onChange={e => onWeightChange(code, e.target.value)}
                                />
                            </td>
                            <td>
                                <button
                                    className="flex justify-center items-center w-7 h-7 cursor-pointer hover:text-red-500 text-gray-500 pr-1.5"
                                    onClick={() => onRemove(code)}>
                                    <XIcon className="w-4 h-4" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Link to={url} className="m-4 bg-blue-600 p-2 block text-center text-white rounded-md">
                Performans &rarr;
            </Link>
        </div>
    );
};

export default SelectedFunds;
