import React, { useMemo } from 'react';

import { ExternalLinkIcon } from '@heroicons/react/outline';
import { SectionHeader } from '../../components';
import { useRequest } from '../../hooks';
import fintablesLogo from '../../assets/fintablesLogo.svg';

const TableView = ({ code, title }) => {
    const [{ response, loading }] = useRequest({
        baseURL: 'https://mobile-api.fintables.com/',
        url: `companies/${code}/summary/?periods=5`,
        headers: {
            Authorization: '',
        },
    });

    const prepareRows = useMemo(() => {
        if (!response[0]?.columns) {
            return [];
        }

        return (
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="divide-y divide-gray-200">
                        {response[0].columns.map(column => (
                            <>
                                <tr className="bg-gray-50">
                                    <th
                                        scope="col"
                                        className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {column.title}
                                    </th>
                                    {column.data.periods.map(period => (
                                        <th
                                            key={`${period.year}/${period.month}`}
                                            scope="col"
                                            className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {period.year}/{period.month}
                                        </th>
                                    ))}
                                </tr>

                                {column.data.values.map(row => (
                                    <tr key={row.title} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {row.title}
                                        </td>
                                        {row.values.map((value, i) => (
                                            <td
                                                key={`${row.title}-${i}`}
                                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {value.toLocaleString(navigator.language, {
                                                    minimumFractionDigits: 0,
                                                })}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }, [response]);

    const renderContent = () => {
        if (loading) {
            return Array.from(Array(10).keys()).map(i => (
                <div
                    key={i}
                    className="animate-pulse flex border-b border-gray-200 flex-row justify-between">
                    <div className="flex flex-1 justify-start px-4 py-3">
                        <div className="h-3 bg-gray-200 rounded w-3/6"></div>
                    </div>
                    <div className="flex flex-1 justify-end px-4 py-3">
                        <div className="h-3 bg-gray-100 rounded w-4/6"></div>
                    </div>
                </div>
            ));
        }

        return prepareRows;
    };

    return (
        <div className="flex flex-col bg-white">
            <SectionHeader
                title={
                    <div className="flex items-center">
                        <img
                            title="Fintables"
                            className="h-8 mr-2 w-auto"
                            src={fintablesLogo}
                            alt="Fintables logo"
                        />
                        {title} ({code}) Fintables Temel Analiz
                    </div>
                }
                RightComponent={
                    <a
                        href={`https://fintables.com/sirketler/${code}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Fintables'a yönlendirir"
                        className="flex text-gray-500 items-center text-xs">
                        <span>Detaylı analiz</span>
                        <ExternalLinkIcon className="h-4 w-4 ml-1" />
                    </a>
                }
            />

            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full overflow-hidden">
                    <div className="min-w-full grid grid-cols-1 divide-y divide-gray-200">
                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableView;
