import React, { useState, useMemo, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import logo from '../assets/borsaekranim-logo.png';
import { Dropdown, Modal } from '../components';
import { EditFavoritesModalContent } from '../modalContents';
import { setFromPath } from '../stores/auth';
import { useUser } from '../hooks';
import { logout } from '../utils/authMethods';
import { getSymbolPath } from '../routes/helpers';
import HeaderSymbolSearch from './HeaderSymbolSearch';

const MenuLink = ({ children, to }) => (
    <NavLink
        to={to}
        activeClassName="bg-blue-100 border-b-2"
        className="px-3 py-2 capitalize rounded-md text-sm leading-5 font-medium focus:outline-none focus:bg-blue-100 transition duration-150 ease-in-out ml-4 text-blue-800 hover:bg-blue-100 hover:border-b-2 border-blue-600">
        {children}
    </NavLink>
);

const MobileMenuLink = ({ children, to, onClick }) => (
    <NavLink
        onClick={onClick}
        to={to}
        activeClassName="bg-blue-200"
        className="mt-1 block capitalize px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 transition duration-150 ease-in-out text-gray-800">
        {children}
    </NavLink>
);

const MobileMenu = ({ navLinks, profileDropdownButtons }) => {
    const user = useUser();
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <>
            <div className="px-2 pt-2 pb-3">
                {navLinks.map(link => (
                    <MobileMenuLink key={link.to} to={link.to}>
                        {link.title}
                    </MobileMenuLink>
                ))}
            </div>
            <div className="py-3 border-t border-gray-700">
                {user ? (
                    <>
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-700">
                                    <span className="text-sm font-medium leading-none text-white">
                                        {user.first_name.charAt(0)}
                                        {user.last_name.charAt(0)}
                                    </span>
                                </span>
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-6 text-gray-600">
                                    {user.first_name} {user.last_name}
                                </div>
                                <div className="text-sm font-medium leading-5 text-gray-400">
                                    {user.email}
                                </div>
                            </div>
                        </div>

                        {profileDropdownButtons.map((buttonGroups, i) => (
                            <div key={`buttonGroup-${i}`}>
                                {buttonGroups.map((button, i) => (
                                    <div key={i} className="px-2 mt-3">
                                        <button
                                            onClick={button.onClick}
                                            className="mt-1 w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700 transition duration-150 ease-in-out">
                                            {button.label}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="px-2">
                        <Link
                            onClick={() => dispatch(setFromPath(location.pathname))}
                            to="/giris-yap"
                            className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700 transition duration-150 ease-in-out">
                            <span>Giriş Yap</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(setFromPath(location.pathname))}
                            to="/kayit-ol"
                            className="mt-1 w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700 transition duration-150 ease-in-out">
                            <span>Ücretsiz Üye ol</span>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

const MenuRightSide = ({ profileDropdownButtons }) => {
    const user = useUser();
    const location = useLocation();
    const dispatch = useDispatch();

    return (
        <div className="relative flex-shrink-0">
            <div className="flex items-center">
                {!user ? (
                    <div className="hidden lg:flex">
                        <Link
                            onClick={() => dispatch(setFromPath(location.pathname))}
                            to="/giris-yap"
                            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-800 hover:text-white bg-gray-300 shadow-sm hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                            <span>Giriş Yap</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(setFromPath(location.pathname))}
                            to="/kayit-ol"
                            className="relative ml-4 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-700 shadow-sm hover:bg-blue-600 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue active:bg-blue-600 transition ease-in-out duration-150">
                            <span>Ücretsiz Üye Ol</span>
                        </Link>
                    </div>
                ) : (
                    <Dropdown
                        dropdownOrigin="right"
                        menuItems={profileDropdownButtons}
                        HeaderComponent={
                            <div className="px-4 py-3">
                                <p className="text-sm leading-5 truncate">
                                    {user.first_name} {user.last_name}
                                </p>
                                <p className="text-sm leading-5 font-medium text-gray-900 truncate">
                                    {user.email}
                                </p>
                            </div>
                        }
                        ButtonComponent={() => (
                            <Dropdown.Button className="flex text-sm rounded-full text-white focus:outline-none focus:ring-2 transition duration-150 ease-in-out">
                                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-blue-700">
                                    <span className="text-sm font-medium leading-none text-white">
                                        {user.first_name.charAt(0)}
                                        {user.last_name.charAt(0)}
                                    </span>
                                </span>
                            </Dropdown.Button>
                        )}
                    />
                )}
            </div>
        </div>
    );
};

const Menu = () => {
    const { t } = useTranslation();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showEditFavoritesModal, setShowEditFavoritesModal] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const navLinks = useMemo(
        () => [
            {
                title: t('module.liveStocks'),
                to: '/canli-borsa',
            },
            {
                title: t('market.currency'),
                to: '/doviz',
            },
            {
                title: t('market.gold'),
                to: '/altin',
            },
            // {
            //     title: t('module.analysis'),
            //     to: '/analiz',
            // },
            {
                title: t('fund.title'),
                to: '/fonmatik',
            },
            // {
            //     title: t('module.academy'),
            //     to: '/akademi',
            // },
        ],
        [t],
    );
    const profileDropdownButtons = useMemo(
        () => [
            [{ label: 'Favoriler', onClick: () => setShowEditFavoritesModal(true) }],
            [{ label: 'Çıkış yap', onClick: () => logout() }],
        ],
        [],
    );

    useEffect(() => {
        setShowMobileMenu(false);
    }, [history.location]);

    return (
        <nav className="bg-white border-b-2">
            <div className="max-w-8xl mx-auto px-4 sm:px-6">
                <div className="relative flex items-center justify-between h-20">
                    <div className="flex items-center px-2 lg:px-0">
                        <Link to="/" className="flex-shrink-0">
                            <img
                                className="block h-8 md:h-12 w-auto"
                                src={logo}
                                alt="Borsa Ekranım"
                            />
                        </Link>
                        <div className="hidden lg:block lg:ml-1">
                            <div className="flex">
                                {navLinks.map(link => (
                                    <MenuLink key={link.to} to={link.to}>
                                        {link.title}
                                    </MenuLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-center lg:ml-6 lg:justify-end">
                        <div className="max-w-lg w-full lg:max-w-xs mx-2 sm:mx-0">
                            <HeaderSymbolSearch
                                onSelected={symbol => history.push(getSymbolPath(symbol))}
                            />
                        </div>
                    </div>
                    <div className="flex lg:hidden">
                        {/* <!-- Mobile menu button --> */}
                        <button
                            onClick={() => setShowMobileMenu(!showMobileMenu)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:text-white transition duration-150 ease-in-out"
                            aria-label="Main menu"
                            aria-expanded="false">
                            <svg
                                className={classNames(
                                    'h-6 w-6',
                                    showMobileMenu ? 'hidden' : 'block',
                                )}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                            <svg
                                className={classNames(
                                    'h-6 w-6',
                                    showMobileMenu ? 'block' : 'hidden',
                                )}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden lg:block lg:ml-4">
                        <div className="flex items-center">
                            {/* <!-- Profile dropdown --> */}
                            <MenuRightSide profileDropdownButtons={profileDropdownButtons} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classNames('lg:hidden', showMobileMenu ? 'block' : 'hidden')}>
                <MobileMenu navLinks={navLinks} profileDropdownButtons={profileDropdownButtons} />
            </div>

            <Modal
                onClose={() => setShowEditFavoritesModal(false)}
                contentClassName="bg-white p-6 m-2 w-full sm:m-8 max-w-2xl"
                contentOverflowClass="overflow-auto"
                show={showEditFavoritesModal}>
                <EditFavoritesModalContent onClose={() => setShowEditFavoritesModal(false)} />
            </Modal>
        </nav>
    );
};

export default Menu;
