import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QuickViewer, SectionHeader, HeaderSymbolSearch, Modal } from '../../components';
import { EditFavoritesModalContent } from '../../modalContents';
import { useFavorites, useUser } from '../../hooks';

const EmptyFavorites = ({ addFavorite }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-center px-4 py-5 sm:p-6">
            <div className="items-center justify-center flex flex-col">
                <svg
                    className="text-gray-900 h-6 w-6 mb-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor">
                    <path
                        d="M11.0489 2.92707C11.3483 2.00576 12.6517 2.00576 12.9511 2.92707L14.4697 7.60083C14.6035 8.01285 14.9875 8.29181 15.4207 8.29181H20.335C21.3037 8.29181 21.7065 9.53143 20.9228 10.1008L16.947 12.9894C16.5965 13.244 16.4499 13.6954 16.5838 14.1074L18.1024 18.7812C18.4017 19.7025 17.3472 20.4686 16.5635 19.8992L12.5878 17.0107C12.2373 16.756 11.7627 16.756 11.4122 17.0107L7.43647 19.8992C6.65276 20.4686 5.59828 19.7025 5.89763 18.7812L7.41623 14.1074C7.5501 13.6954 7.40344 13.244 7.05296 12.9894L3.07722 10.1008C2.2935 9.53143 2.69628 8.29181 3.665 8.29181H8.57929C9.01251 8.29181 9.39647 8.01285 9.53034 7.60083L11.0489 2.92707Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t('action.addFavorite')}
                </h3>
                <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Favori sembollerinizi ekleyerek daha kolay takip edebilirsiniz.</p>
                </div>
                <div className="mt-5 w-full sm:flex sm:items-center">
                    <HeaderSymbolSearch
                        autoFocus={false}
                        onSelected={symbol => {
                            addFavorite(symbol.id);
                        }}
                        inputClassName="border-gray-300"
                    />
                </div>
            </div>
        </div>
    );
};

const Overview = () => {
    const { t } = useTranslation();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const { favorites, addFavorite } = useFavorites();
    const user = useUser();
    const tabs = useMemo(
        () =>
            [
                user ? { title: t('general.favorite'), value: 'favorite' } : null,
                { title: t('market.index'), codes: ['XU100', 'XU050', 'XU030', 'XBANK'] },
                { title: t('market.currency'), codes: ['USDTRY', 'EURTRY', 'EURUSD', 'GBPUSD'] },
                { title: t('market.gold'), codes: ['GLD'] },
                // { title: t('market.bond'), codes: ['GLD'] },
                { title: t('market.commodity'), codes: ['COTTON'] },
                { title: t('market.foreign'), codes: ['DAX'] },
            ].filter(obj => obj !== null),
        [t, user],
    );
    const selectedTab = tabs[activeTabIndex];
    const [showEditFavoritesModal, setShowEditFavoritesModal] = useState(false);
    const favoritesLoading = false;

    const BodyContent = useMemo(() => {
        if (selectedTab.value === 'favorite') {
            if (favoritesLoading) {
                return (
                    <div className="flex items-center justify-center py-10">
                        {t('info.loading')}...
                    </div>
                );
            }

            if (favorites.length === 0) {
                return <EmptyFavorites addFavorite={addFavorite} />;
            } else {
                return (
                    <>
                        <QuickViewer codes={favorites} />
                        <div className="flex items-center border-t border-gray-200 px-6">
                            <button
                                onClick={() => setShowEditFavoritesModal(true)}
                                className="text-blue-600 py-4 flex-1 focus:outline-none text-left font-medium inline-block">
                                {t('action.editFavorites')}
                            </button>
                        </div>
                    </>
                );
            }
        } else {
            return <QuickViewer codes={selectedTab.codes} />;
        }
    }, [addFavorite, favorites, favoritesLoading, selectedTab.codes, selectedTab.value, t]);

    return (
        <div className="bg-white rounded-lg shadow">
            <SectionHeader
                title={t('general.market_plural')}
                withTabs={{ tabs, activeTabIndex, setActiveTabIndex }}
            />

            {BodyContent}

            <Modal
                onClose={() => setShowEditFavoritesModal(false)}
                contentClassName="bg-white p-6 m-2 w-full sm:m-8 max-w-2xl"
                contentOverflowClass="overflow-auto"
                show={showEditFavoritesModal}>
                <EditFavoritesModalContent onClose={() => setShowEditFavoritesModal(false)} />
            </Modal>
        </div>
    );
};

export default Overview;
