import React, { useState, useEffect, createRef } from 'react';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

let onCompleteCalled = false;

const CodeInput = ({ fields, onChange = () => {}, onComplete = () => {} }) => {
    const [values, setValues] = useState([]);
    const [refs, setRefs] = React.useState([]);

    useEffect(() => {
        setValues(Array.from(Array(fields), (_, i) => ''));

        setRefs(refs =>
            Array(fields)
                .fill()
                .map((_, i) => refs[i] || createRef()),
        );
    }, [fields]);

    useEffect(() => {
        const code = values.join('');

        onChange(code);

        if (code.length === fields && !onCompleteCalled) {
            onComplete(code);
            onCompleteCalled = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, fields]);

    const handleChange = (targetValue, i) => {
        if (targetValue.length > 1) {
            targetValue = targetValue.substr(targetValue.length - 1);
        }

        setValues(values.map((v, vIndex) => (vIndex === i ? targetValue : v)));

        if (targetValue) {
            if (refs[i + 1]) {
                refs[i + 1].current.focus();
            } else {
                for (let index = 0; index < refs.length; index++) {
                    const loopRef = refs[index].current;

                    if (!loopRef.value) {
                        loopRef.focus();
                        return;
                    }
                }

                refs[i].current.blur();
            }
        }
    };

    const onKeyDown = (e, i) => {
        const targetValue = e.target.value;
        const prevTarget = refs[i - 1] ?? null;
        const nextTarget = refs[i + 1] ?? null;

        switch (e.keyCode) {
            case BACKSPACE_KEY:
                if (!targetValue && prevTarget) {
                    prevTarget.current.focus();
                }
                break;

            case LEFT_ARROW_KEY:
                e.preventDefault();
                if (prevTarget) {
                    prevTarget.current.focus();
                }
                break;

            case RIGHT_ARROW_KEY:
                e.preventDefault();
                if (nextTarget) {
                    nextTarget.current.focus();
                } else {
                    handleChange(e.target.value, i);
                }
                break;

            default:
                break;
        }
    };

    const handlePaste = e => {
        const text = e.clipboardData.getData('Text');

        if (text.length === fields) {
            setValues(Array.from(text));
        }
    };

    return (
        <div className="flex -ml-2">
            {values.map((v, i) => (
                <div key={i} className="flex-1 ml-2">
                    <input
                        autoComplete="false"
                        autoFocus={i === 0}
                        ref={refs[i]}
                        className="shadow-sm focus:ring-blue-500 block border-gray-300 rounded-md h-12 font-lg focus:border-blue-800 mr-2 text-center sm:text-lg w-full"
                        value={values[i] ?? ''}
                        onFocus={e => e.target.select()}
                        onKeyDown={e => onKeyDown(e, i)}
                        onChange={e => handleChange(e.target.value, i)}
                        onPaste={i === 0 ? handlePaste : undefined}
                        type="tel"
                        pattern="[0-9]*"
                    />
                </div>
            ))}
        </div>
    );
};

export default CodeInput;
