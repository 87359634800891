import React, { useMemo } from 'react';
import classNames from 'classnames';

const TableView = ({ data, loading }) => {
    const prepareRows = useMemo(() => {
        if (!data.rows) {
            return [];
        }

        return data.rows.map((row, i) => (
            <dl
                key={i}
                className={classNames(
                    'flex hover:bg-gray-100 border-gray-200 flex-row justify-between',
                    i !== data.rows.length - 1 && 'border-b',
                )}>
                <dt className="px-4 py-3 text-left whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                    {row.title}
                </dt>
                <dd className="px-4 py-3 text-right whitespace-nowrap text-sm leading-5 text-gray-500">
                    {row.value.length > 0 ? row.value : '--'}
                </dd>
            </dl>
        ));
    }, [data.rows]);

    const renderContent = () => {
        if (loading) {
            return Array.from(Array(10).keys()).map(i => (
                <div key={i} className="animate-pulse flex border-b border-gray-200 flex-row justify-between">
                    <div className="flex flex-1 justify-start px-4 py-3">
                        <div className="h-3 bg-gray-200 rounded w-3/6"></div>
                    </div>
                    <div className="flex flex-1 justify-end px-4 py-3">
                        <div className="h-3 bg-gray-100 rounded w-4/6"></div>
                    </div>
                </div>
            ));
        }

        return prepareRows;
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg">
                    <div className="bg-white min-w-full grid grid-cols-1 sm:grid-cols-2">
                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableView;
