import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppLayout, AuthLayout, HasRightSideLayout } from '../layouts';
import Home from '../screens/Home';
import Login from '../screens/Login';
import Register from '../screens/Register';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import PhoneVerification from '../screens/PhoneVerification';
import Intro from '../screens/Intro';
import Calendar from '../screens/Calendar';
import News from '../screens/News';
import SymbolDetail from '../screens/SymbolDetail';
import About from '../screens/About';
import Kvkk from '../screens/Kvkk';
import Currency from '../screens/Currency';
import Gold from '../screens/Gold';
import Campaign from '../screens/Campaign';
import LiveStocks from '../screens/LiveStocks';
// import Analysis from '../screens/Analysis';
import Poll from '../screens/Fund/Poll';
import Distribution from '../screens/Fund/Distribution';
import Performance from '../screens/Fund/Performance';
import Welcome from '../screens/Fund/Welcome';
import OG from '../screens/OG';

const AppRoutes = () => {
    const { user, fromPath } = useSelector(state => state.auth);

    return (
        <Switch>
            <Route
                path={[
                    '/giris-yap',
                    '/kayit-ol',
                    '/sms-dogrulama',
                    '/sifremi-unuttum',
                    '/sifre-sifirla',
                ]}>
                {user ? (
                    <Redirect to={fromPath ?? '/'} />
                ) : (
                    <AuthLayout>
                        <Route exact path="/giris-yap" component={Login} />
                        <Route exact path="/kayit-ol" component={Register} />
                        <Route exact path="/sifremi-unuttum" component={ForgotPassword} />
                        <Route
                            exact
                            path="/sifre-sifirla/:userId/:token"
                            component={ResetPassword}
                        />
                        <Route
                            exact
                            path="/sms-dogrulama"
                            render={props => {
                                // user must come from kayit-ol url with props
                                if (!props.location.state) {
                                    return <Redirect to="/kayit-ol" />;
                                }

                                return <PhoneVerification {...props} />;
                            }}
                        />
                    </AuthLayout>
                )}
            </Route>

            <Route path="/">
                <AppLayout>
                    <Switch>
                        <Route exact path="/tanitim" component={Intro} />
                        <Route exact path="/hakkimizda" component={About} />
                        <Route exact path="/kvkk" component={Kvkk} />
                        <Route exact path="/canli-borsa" component={LiveStocks} />
                        <Route exact path="/fintables-kampanya" component={Campaign} />

                        <Route
                            exact
                            path={[
                                '/hisse/:symbol',
                                '/endeks/:symbol',
                                '/viop/:symbol',
                                '/forex/:symbol',
                                '/sembol/:symbol',
                            ]}
                            component={SymbolDetail}
                        />

                        <Route exact path="/og/:symbol" component={OG} />

                        <HasRightSideLayout>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/doviz" component={Currency} />
                            <Route exact path="/altin" component={Gold} />
                            <Route exact path="/ekonomik-takvim" component={Calendar} />
                            <Route exact path="/haberler" component={News} />
                            <Route exact path="/fonmatik" component={Welcome} />
                            <Route exact path="/fonmatik/anket" component={Poll} />
                            <Route
                                exact
                                path="/fonmatik/performans/:funds/"
                                component={Performance}
                            />
                            <Route
                                exact
                                path="/fonmatik/dagilim/:interest/:score"
                                component={Distribution}
                            />
                        </HasRightSideLayout>
                    </Switch>
                </AppLayout>
            </Route>
        </Switch>
    );
};

export default AppRoutes;
