import React from 'react';

import moment from 'moment';

const NewItem = ({ item, onClick }) => {
    const date = moment(item.date).fromNow();

    return (
        <button onClick={() => onClick(item)} className="text-left">
            <p className="text-sm leading-5 text-gray-500">
                <time dateTime={item.date}>{date}</time>
            </p>
            <div className="block">
                <div className="mt-1 text-md leading-5 font-medium text-gray-900">{item.title}</div>
            </div>
        </button>
    );
};

export default NewItem;
