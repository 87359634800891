import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { useSelector } from 'react-redux';

import { selectAll } from '../stores/symbols';
import { isMobile } from '../utils/helpers';

const prepareString = string => {
    var trCharacters = {
        çÇ: 'c',
        ğĞ: 'g',
        şŞ: 's',
        üÜ: 'u',
        ıİ: 'i',
        öÖ: 'o',
    };

    for (var key in trCharacters) {
        string = string.replace(new RegExp('[' + key + ']', 'g'), trCharacters[key]);
    }

    return string.toLowerCase();
};

const getSuggestions = (value, symbols) => {
    const inputValue = prepareString(value.trim());
    const inputLength = inputValue.length;

    return inputLength === 0
        ? []
        : symbols
              .filter(symbol => {
                  const code = prepareString(getSuggestionValue(symbol));

                  if (code.slice(0, inputLength) === inputValue) {
                      symbol.matchLevel = 0;
                      return true;
                  }

                  if (code.includes(inputValue)) {
                      symbol.matchLevel = 1;
                      return true;
                  }

                  return false;
              })
              .sort(function (a, b) {
                  // alphabetical sorting
                  return getSuggestionValue(a) > getSuggestionValue(b) ? 1 : -1;
              })
              .sort((a, b) => {
                  // sort by match level (lower on top)
                  if (a.matchLevel < b.matchLevel) return -1;
                  if (a.matchLevel > b.matchLevel) return 1;

                  // sort by length if matchLevel equals (shorter on top)
                  const aCodeLength = a.id.length;
                  const bCodeLength = b.id.length;
                  if (aCodeLength < bCodeLength) return -1;
                  if (aCodeLength > bCodeLength) return 1;

                  return 0;
              })
              .slice(0, 6);
};

const getSuggestionValue = suggestion => suggestion.id;

const renderSuggestion = ({ prefix, id }, { isHighlighted }) => (
    <div
        role="option"
        aria-selected={isHighlighted}
        className={classNames(
            isHighlighted ? 'text-white bg-blue-700' : 'text-gray-900',
            'group cursor-pointer select-none relative p-2',
        )}>
        <div className="flex justify-between">
            <span className="flex-shrink-0 font-semibold block truncate">{id}</span>
            <span
                className={classNames(
                    isHighlighted ? 'text-blue-300' : 'text-gray-400',
                    'flex-shrink-0 font-normal text-xs truncate',
                )}>
                {prefix}
            </span>
        </div>
    </div>
);

const SuggestionsContainer = ({ containerProps, children, query }) => (
    <div {...containerProps} className="relative">
        <div className="absolute z-50 mt-1 w-full">
            <div className="rounded-md shadow-md bg-white overflow-hidden">
                <div
                    tabIndex="-1"
                    role="listbox"
                    className="max-h-60 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                    {children}
                </div>
            </div>
        </div>
    </div>
);

const SearchInput = inputProps => (
    <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-blue-900" fill="currentColor" viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
        <input
            {...inputProps}
            id="search"
            className={classNames(
                'block w-full pl-10 pr-3 py-2 border border-gray-500 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm transition duration-150 ease-in-out',
                inputProps.className,
            )}
            type="search"
        />
    </div>
);

const HeaderSymbolSearch = ({
    inputClassName,
    onSelected,
    resetValueOnSelect = true,
    autoFocus = !isMobile,
    placeholder = 'Sembol ara',
}) => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const symbols = useSelector(selectAll);
    const clonedSymbols = useMemo(() => symbols.map(symbol => ({ ...symbol })), [symbols]); // we extending objects while sorting

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, clonedSymbols));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            focusInputOnSuggestionClick={false}
            highlightFirstSuggestion={!isMobile}
            renderInputComponent={SearchInput}
            onSuggestionSelected={(e, { suggestion }) => {
                if (resetValueOnSelect) {
                    setValue('');
                }

                onSelected(suggestion);
            }}
            theme={{ container: { width: '100%' } }}
            renderSuggestionsContainer={SuggestionsContainer}
            inputProps={{
                placeholder,
                className: inputClassName,
                value,
                onChange,
                autoFocus,
            }}
        />
    );
};

export default HeaderSymbolSearch;
