// @flow

import React, { useMemo } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import classNames from 'classnames';

type Params = {
    columns: Array<Object>,
    data: Array<any>,
    rowSelection?: boolean,
    getRowProps?: any => Object,
    setSelectedRowIds?: (Array<string>) => void,
    noShadow?: boolean,
    noRounded?: boolean,
    className?: string,
    loading?: boolean,
};

const getRowId = (row, relativeIndex, parent) => {
    const rowId = row.id ? row.id : `relativeId-${relativeIndex}`;

    return parent ? [parent.id, rowId].join('.') : rowId;
};

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            ref={resolvedRef}
            {...rest}
        />
    );
});

const Table = ({
    columns,
    data = [],
    rowSelection = false,
    getRowProps = () => {},
    setSelectedRowIds,
    noShadow = false,
    noRounded = false,
    className,
    loading,
}: Params) => {
    const tableInstance = useTable({ columns, data, getRowId }, useSortBy, useRowSelect, hooks => {
        if (rowSelection) {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    ),
                },
                ...columns,
            ]);
        }
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        visibleColumns,
        rows,
        prepareRow,
        state: { selectedRowIds },
    } = tableInstance;

    React.useEffect(() => {
        if (setSelectedRowIds) {
            setSelectedRowIds(Object.keys(selectedRowIds));
        }
    }, [setSelectedRowIds, selectedRowIds]);

    const loadingContent = useMemo(
        () =>
            [1, 2, 3, 4, 5, 6].map(rowId => (
                <tr key={rowId}>
                    {visibleColumns.map((col, i) => (
                        <td key={i} className="px-6 py-4 whitespace-nowrap text-sm leading-5">
                            <div className="animate-pulse flex space-x-4">
                                {col.id === 'selection' ? (
                                    <IndeterminateCheckbox disabled />
                                ) : (
                                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                                )}
                            </div>
                        </td>
                    ))}
                </tr>
            )),
        [visibleColumns],
    );

    const getCellClassName = cell => {
        if (!cell.column.className) return;

        if (typeof cell.column.className === 'function') {
            return cell.column.className(cell);
        }

        return cell.column.className;
    };

    return (
        <div
            className={classNames(
                'flex flex-col bg-white overflow-hidden',
                noShadow === false && 'shadow',
                noRounded === false && 'sm:rounded-lg',
                className,
            )}>
            {/* TODO: Dropdown for selected rows actions */}
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps(),
                                                )}
                                                className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                {column.render('Header')}

                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <svg
                                                            className="h-4 w-4 inline-block align-top"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            fill="none">
                                                            <path
                                                                d="M19 14L12 21M12 21L5 14M12 21L12 3"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            className="h-4 w-4 inline-block align-top"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            fill="none">
                                                            <path
                                                                d="M5 10L12 3M12 3L19 10M12 3V21"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody
                                {...getTableBodyProps()}
                                className="bg-white divide-y divide-gray-200 text-gray-900">
                                {loading && data.length === 0
                                    ? loadingContent
                                    : rows.map(row => {
                                          prepareRow(row);
                                          return (
                                              <tr {...row.getRowProps(getRowProps(row))}>
                                                  {row.cells.map(cell => {
                                                      return (
                                                          <td
                                                              {...cell.getCellProps({
                                                                  className: classNames(
                                                                      'px-6 py-4 whitespace-nowrap text-sm leading-5',
                                                                      getCellClassName(cell),
                                                                  ),
                                                              })}>
                                                              {cell.render('Cell')}
                                                          </td>
                                                      );
                                                  })}
                                              </tr>
                                          );
                                      })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
