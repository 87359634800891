import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
    SymbolIntradayChart,
    SymbolHistoricalChart,
    SectionHeader,
    Modal,
    Tippy,
} from '../../components';
import { useRequest, useLicence } from '../../hooks';

const now = moment();
const formattedNow = now.format('Y-MM-DD');

const ranges = [
    {
        title: 'Güniçi',
        startDate: 'intraday',
    },
    {
        title: '1 Hafta',
        startDate: now.clone().subtract(1, 'week').format('Y-MM-DD'),
        endDate: formattedNow,
    },
    {
        title: '1 Ay',
        startDate: now.clone().subtract(1, 'month').format('Y-MM-DD'),
        endDate: formattedNow,
    },
    {
        title: '6 Ay',
        startDate: now.clone().subtract(6, 'months').format('Y-MM-DD'),
        endDate: formattedNow,
    },
    {
        title: '1 Yıl',
        startDate: now.clone().subtract(1, 'year').format('Y-MM-DD'),
        endDate: formattedNow,
    },
];

const prepareResponse = res => [
    {
        id: 'price',
        color: 'rgba(35, 56, 118, 0.2)',
        data: res.map(({ x, y }) => ({ x: new Date(x), y })),
    },
];

const Chart = ({ code, prefix, data }) => {
    const { t } = useTranslation();
    const realtimePriceLicence = useLicence('realtime_prices');
    const [startDate, setStartDate] = useState(ranges[0].startDate);
    const [showIframeModal, setShowIframeModal] = useState(false);
    const [{ response, loading }] = useRequest(
        startDate === 'intraday'
            ? `/symbols/${code}/intraday/`
            : `/symbols/${code}/historical/?start_date=${startDate}&end_date=${formattedNow}`,
        {
            prepareResponse,
        },
    );

    const title = useMemo(
        () =>
            prefix
                ? prefix === 'FX' && code.length === 6
                    ? `${code.substring(0, 3) + '/' + code.substring(3, 6)} Grafik`
                    : prefix === 'IMKBX'
                    ? `${code} - ${data.title || ''} Endeks Grafiği`
                    : `${code} Grafik`
                : '',
        [prefix, code, data],
    );

    return (
        <>
            <SectionHeader
                title={title}
                withFilterButtons={{
                    buttons: ranges,
                    selectedValue: startDate,
                    setSelectedValue: setStartDate,
                    buttonValueKey: 'startDate',
                }}
                actionButtons={[
                    {
                        title: 'Teknik Analiz',
                        onClick: () => setShowIframeModal(true),
                        disabledClassName: 'cursor-not-allowed hover:opacity-50',
                        disabled: !realtimePriceLicence,
                        Render: ButtonComponent => {
                            if (realtimePriceLicence) {
                                return <ButtonComponent />;
                            } else {
                                return (
                                    <Tippy
                                        content={t('error.loginRequiredForTechnicalAnalysis')}
                                        className="text-center bg-gray-900 text-gray-200 font-light">
                                        <span tabIndex="0">
                                            <ButtonComponent />
                                        </span>
                                    </Tippy>
                                );
                            }
                        },
                    },
                ]}
            />
            <div className="h-48 flex justify-center items-center">
                {loading ? (
                    <div>Yükleniyor...</div>
                ) : startDate === 'intraday' ? (
                    <SymbolIntradayChart code={code} data={response} />
                ) : (
                    <SymbolHistoricalChart code={code} data={response} />
                )}
            </div>

            <Modal
                contentClassName="absolute inset-0 m-5 rounded-lg"
                contentStyle={modalContentStyle}
                onClose={() => setShowIframeModal(false)}
                show={showIframeModal}>
                <div className="flex flex-col h-full w-full">
                    <div className="pl-5 flex items-center justify-between border-b border-gray-200 relative">
                        <h3
                            className="text-lg pr-10 leading-6 font-medium text-gray-400"
                            id="modal-headline">
                            Teknik Analiz
                        </h3>
                        <button
                            onClick={() => setShowIframeModal(false)}
                            type="button"
                            className="flex h-full justify-end p-5 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                            aria-label="Close">
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <iframe
                        src={`https://calendar.fintables.com/tv/index.html?symbol=${code}`}
                        className="w-full h-full"
                        title="Teknik Analiz"></iframe>
                </div>
            </Modal>
        </>
    );
};

const modalContentStyle = { backgroundColor: '#131823' };

export default Chart;
