// @flow

import api from '../core/api';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

type List = { id: string, codes: string[] };

const listsAdapter = createEntityAdapter<List>();

const listsSlice = createSlice({
    name: 'lists',
    initialState: listsAdapter.getInitialState({ loading: false }),
    reducers: {
        listsLoading(state, action) {
            if (state.loading === false) {
                state.loading = true;
            }
        },
        listsReceived(state, action) {
            if (state.loading === true) {
                listsAdapter.setAll(state, action.payload);
                state.loading = false;
            }
        },
    },
});

export const { listsLoading, listsReceived } = listsSlice.actions;
export const { selectAll, selectById, selectEntities } = listsAdapter.getSelectors(
    state => state.lists,
);

export const init = () => (dispatch: $FlowFixMe) => {
    dispatch(listsLoading());
    api.get('lists/').then(res => dispatch(listsReceived(res.data))); // Catch
};

export default listsSlice.reducer;
