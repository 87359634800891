import React, { useEffect, useState } from 'react';
import { useRequest } from '../../hooks';
import moment from 'moment';

const groupByDay = data => {
    let groups = [],
        lastDay = null;

    data.forEach(row => {
        if (row.day === lastDay) {
            groups[groups.length - 1].push(row);
        } else {
            lastDay = row.day;
            groups.push([row]);
        }
    });

    return groups;
};

const CalendarTable = ({ filter, limit }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [{ loading, response }] = useRequest(`calendar/?filter=${filter}`);

    useEffect(() => {
        setFilteredData(
            groupByDay(
                (filter === 'today'
                    ? response.filter(row => new Date(`${row.day} ${row.time}`) > new Date())
                    : response
                ).slice(0, limit),
            ),
        );
    }, [filter, limit, response]);

    return (
        <div className="flex flex-col overflow-hidden">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full overflow-hidden">
                    {loading ? (
                        <div className="flex items-center justify-center py-6">Yükleniyor...</div>
                    ) : (
                        <table className="min-w-full divide-y divide-gray-300 border-b border-gray-200">
                            {filteredData.map(group => (
                                <React.Fragment key={group[0].day}>
                                    <thead>
                                        <tr>
                                            <th
                                                colSpan={2}
                                                className="text-sm text-left font-medium px-3 py-3 text-gray-500">
                                                {moment(group[0].day).format('DD MMMM')}
                                            </th>
                                            <th className="text-sm px-3 py-3 text-right font-normal text-gray-500">
                                                Açıklanan
                                            </th>
                                            <th className="text-sm px-3 py-3 text-right font-normal text-gray-500">
                                                Beklenti
                                            </th>
                                            <th className="text-sm px-3 py-3 text-right font-normal text-gray-500">
                                                Önceki
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {group.map(row => (
                                            <tr
                                                key={`${row.country.title}${row.title}`}
                                                className="text-gray-600 h-13 hover:bg-blue-100">
                                                <td className="text-xs w-16">
                                                    <span className=" rounded-r-full border border-gray-300 border-l-0 ring-1 ring-black ring-opacity-5 bg-gray-50 pl-3 pr-2 py-1 text-gray-800 font-medium">
                                                        {row.time}
                                                    </span>
                                                </td>
                                                <td className="text-sm text-gray-900">
                                                    {row.title}
                                                    <div className="text-xs text-gray-500">
                                                        <img
                                                            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${row.country.code.toLowerCase()}.svg`}
                                                            alt={row.country.title}
                                                            className="h-3 inline mr-1 -mt-0.5"
                                                        />
                                                        {row.country.title}
                                                    </div>
                                                </td>
                                                <td className="px-3 py-4 text-right text-sm leading-5 font-medium">
                                                    {row.actual}
                                                </td>
                                                <td className="px-3 py-4 text-right text-sm leading-5 font-medium">
                                                    {row.forecast}
                                                </td>
                                                <td className="px-3 py-4 text-right text-sm leading-5 font-medium">
                                                    {row.previous}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </React.Fragment>
                            ))}
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CalendarTable;
