// @flow

import React from 'react';
import classNames from 'classnames';

import Button from './Button';
import type { buttonColorTypes } from './Button';

type Params = {
    title: string,
    titleMiniText?: string,
    withTabs: {
        tabs: Array<{ title: string }>,
        setActiveTabIndex: (number | string) => void,
        activeTabIndex: number | string,
        tabLinkClassName: string,
    },
    actionButtons?: Array<{
        title: string,
        onClick: Function,
        svg?: React$Node,
        color?: buttonColorTypes,
    }>,
};

const TabLink = ({ onClick, children, active }) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                'whitespace-nowrap py-4 px-1 border-b-2 text-sm leading-5 focus:outline-none capitalize',
                active
                    ? 'border-blue-500 text-blue-600 focus:text-blue-800 focus:border-blue-700'
                    : 'border-transparent text-gray-500  focus:text-gray-700 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300',
            )}>
            {children}
        </button>
    );
};

const PageHeader = ({
    title,
    titleMiniText,
    withTabs: { activeTabIndex, setActiveTabIndex, tabs, tabLinkClassName } = {},
    actionButtons = [],
}: Params) => {
    return (
        <div className="mb-4 w-full">
            <div className="lg:flex lg:items-center lg:justify-between w-full">
                <div className="flex-1 flex-row min-w-0">
                    <h1 className="text-2xl capitalize inline-flex font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate items-center">
                        {title}
                        <span className="text-base font-normal ml-2">{titleMiniText}</span>
                    </h1>
                </div>
                {actionButtons.length > 0 && (
                    <div className="mt-2 flex space-x-2 lg:mt-0 lg:ml-4">
                        {actionButtons.map(({ svg, onClick, title, color, Render, ...rest }) => {
                            if (Render) {
                                return Render(() => (
                                    <Button
                                        onClick={onClick}
                                        svg={svg ? svg : null}
                                        label={title}
                                        color={color}
                                        key={title}
                                        {...rest}
                                    />
                                ));
                            } else {
                                return (
                                    <Button
                                        onClick={onClick}
                                        svg={svg ? svg : null}
                                        label={title}
                                        color={color}
                                        key={title}
                                        {...rest}
                                    />
                                );
                            }
                        })}
                    </div>
                )}
            </div>

            {tabs && (
                <div>
                    <div className="sm:hidden mt-2">
                        <select
                            value={activeTabIndex}
                            onChange={e => setActiveTabIndex(e.target.value)}
                            className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150">
                            {tabs.map((tab, i) => (
                                <option key={i} value={i}>
                                    {tab.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8">
                                {tabs.map((tab, i) => {
                                    return (
                                        <TabLink
                                            key={tab.title}
                                            active={activeTabIndex === i}
                                            onClick={() => setActiveTabIndex(i)}>
                                            {tab.title}
                                        </TabLink>
                                    );
                                })}
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PageHeader;
