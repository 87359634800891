// @flow

import axios from 'axios';

import { API_URL } from './constants';
import { toast } from '../components';

const jsonApi = axios.create({
    baseURL: API_URL,
    timeout: 0,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

function setTokenToApis(token: string) {
    jsonApi.defaults.headers.common.Authorization = `Bearer ${token}`;
}

function setLanguageToApis(language: 'tr' | 'ar') {
    jsonApi.defaults.headers.common['Accept-Language'] = language;
}

jsonApi.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // TODO: refresh token if needed

            return Promise.reject(error.response);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js

            // TODO: add update transition if toast id exists
            toast.simple({
                title:
                    error.code === 'ECONNABORTED'
                        ? 'Bağlantı zaman aşımına uğradı.'
                        : !window.navigator.onLine
                        ? 'İnternete bağlanılamadı.'
                        : 'Sunucuya bağlanırken hata oluştu.',
                icon: 'error',
                toastId: 'apiAnErrorOccured',
            });
        } else {
            // Something happened in setting up the request that triggered an Error
        }

        return Promise.reject(error);
    },
);

export { jsonApi as default, setTokenToApis, setLanguageToApis };
