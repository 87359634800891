import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { usePrice } from '../../hooks';
import { getSymbolPath } from '../../routes/helpers';

const Row = ({ code, onSelect }) => {
    const { formatted, symbol } = usePrice(code);

    return (
        <tr
            onClick={onSelect}
            className={classNames(
                'border-t h-10 text-sm hover:bg-blue-100 border-gray-200 hover:border-blue-100',
            )}>
            <td className="pl-3 font-medium w-1/2">
                <Link
                    to={getSymbolPath(symbol)}
                    title={`${symbol?.title} - ${symbol?.id}`}
                    className="block">
                    {code}
                </Link>
            </td>
            <td className="text-right text-base w-1/4 tabular-nums">{formatted.lastPrice}</td>
            <td className={`text-right pr-4 text-${formatted.color}-500 w-1/4 tabular-nums`}>
                {formatted.changePercent}%
            </td>
        </tr>
    );
};

export default Row;
