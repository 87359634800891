import React from 'react';
import Row from './Row';

const MiniPriceTable = ({ codes }) => (
    <table className="w-full">
        {/*
        <thead>
            <tr className="h-10 text-gray-500 text-sm text-right">
                <th className="font-medium text-left pl-3">Sembol</th>
                <th className="font-medium">Son Fiyat</th>
                <th className="font-medium">Değişim</th>
                <th></th>
            </tr>
        </thead>
        */}
        <tbody>
            {codes.map(code => (
                <Row key={code} code={code} />
            ))}
        </tbody>
    </table>
);

export default MiniPriceTable;
