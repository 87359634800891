export default {
    general: {
        symbol: 'Sembol',
        price: 'Fiyat',
        favorite: 'Favori',
        market: 'Piyasa',
        market_plural: 'Piyasalar',
    },
    action: {
        addFavorite: 'Favori ekle',
        editFavorites: 'Favorileri düzenle',
        remove: 'Kaldır',
    },
    error: {
        unauthorizedAction: 'Yetkisiz işlem',
        loginRequired: 'Bu işlemi gerçekleştirebilmek için giriş yapmalısınız.',
        loginRequiredForTechnicalAnalysis:
            'Teknik analiz grafiğini görüntüleyebilmek için giriş yapmalısınız.',
        loginRequiredForFavorites: 'Favoriler özelliğini kullanabilmek için giriş yapmalısınız.',
    },
    direction: {},
    info: { loading: 'Yükleniyor' },
    input: {
        symbol: 'Sembol',
    },
    market: {
        gold: 'Altın',
        currency: 'Döviz',
        index: 'Endeks',
        commodity: 'Emtia',
        bond: 'Tahvil',
        foreign: 'Yurt Dışı',
    },
    symbol: {
        change: 'Değişim',
    },
    module: {
        analysis: 'Analiz',
        liveStocks: 'Canlı borsa',
        academy: 'Akademi',
    },
    analysis: {
        yield: 'Getiri analizi',
    },
    fund: {
        title: 'Fonmatik',
        distribution: 'Fon Dağılımı',
        performance: 'Fon Portföy Performansı',
        performanceLoading: 'Fon Portföyünüz Oluşturuluyor...',
    },
};
