import React from 'react';

import Button from '../Button';

type Params = {
    buttons: Array<{
        label: string,
        onClick?: Function,
        color?: $Keys<typeof buttonColors>,
        type?: string,
    }>,
};

const Header = ({ buttons = [] }: Params) => {
    return (
        <div className="mt-4 border-t border-gray-200 pt-5">
            <div className="flex justify-end space-x-3">
                {buttons.map((button, i) => (
                    <Button
                        label={button.label}
                        onClick={button.onClick}
                        color={button.color}
                        type={button.type}
                    />
                ))}
            </div>
        </div>
    );
};

export default Header;
