import api from '../core/api';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import pusher from '../core/pusher';

const pricesAdapter = createEntityAdapter();

const pricesSlice = createSlice({
    name: 'prices',
    initialState: pricesAdapter.getInitialState({ loading: false }),
    reducers: {
        pricesLoading(state, action) {
            if (state.loading === false) {
                state.loading = true;
            }
        },
        pricesReceived(state, action) {
            if (state.loading === true) {
                pricesAdapter.setAll(state, action.payload);
                state.loading = false;
            }
        },
        priceUpdated: pricesAdapter.updateOne,
        pricesUpdated: pricesAdapter.updateMany,
    },
});

export const { pricesLoading, pricesReceived, priceUpdated, pricesUpdated } = pricesSlice.actions;
export const { selectAll, selectById } = pricesAdapter.getSelectors(state => state.prices);

const subscribeToPriceChanges = realtime => dispatch => {
    const channel = pusher.subscribe(realtime ? 'realtime_prices' : 'delayed_prices');

    channel.bind('update', data => {
        const updates = Object.entries(data).map(([id, [lastPrice, dayClose]]) => ({
            id,
            changes: { lastPrice, dayClose },
        }));

        dispatch(pricesUpdated(updates));
    });
};

export const init = realtime => dispatch => {
    dispatch(pricesLoading());
    dispatch(subscribeToPriceChanges(realtime));

    api.get('prices/').then(res =>
        dispatch(
            pricesReceived(
                Object.entries(res.data).map(([id, [lastPrice, dayClose]]) => ({
                    id,
                    lastPrice,
                    dayClose,
                })),
            ),
        ),
    ); // Catch
};

export default pricesSlice.reducer;
