import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { usePrice } from '../../hooks';
import { getSymbolPath } from '../../routes/helpers';

const Row = ({ code, active, onSelect }) => {
    const { formatted, symbol, color } = usePrice(code, { dynamicColor: true });

    return (
        <tr
            onClick={onSelect}
            className={classNames(
                'border-t h-10 text-sm cursor-pointer',
                active && 'bg-blue-100 border-blue-100',
                !active && 'border-gray-200',
            )}>
            <td className="pl-3 font-medium">{code}</td>
            <td className={`text-right text-base text-${color}-500 tabular-nums`}>
                {formatted.lastPrice}
            </td>
            <td className={`text-right text-${formatted.color}-500 tabular-nums`}>
                {formatted.changePercent}%
            </td>
            <td
                className={`text-right hidden md:table-cell text-${formatted.color}-500 tabular-nums`}>
                {formatted.change}
            </td>
            <td className="text-right">
                <Link
                    to={getSymbolPath(symbol)}
                    title={
                        symbol?.prefix === 'FX'
                            ? `${symbol.id} - ${symbol.title}`
                            : `${symbol?.title} (${symbol?.id})`
                    }
                    className={classNames(
                        'hover:underline inline-block py-2 px-3',
                        !active && 'text-gray-400',
                    )}>
                    <span className="hidden md:inline">
                        {symbol?.prefix === 'KIYM'
                            ? symbol.title
                            : symbol?.prefix === 'FX'
                            ? symbol.id
                            : 'İncele'}
                    </span>{' '}
                    &rarr;
                </Link>
            </td>
        </tr>
    );
};

export default Row;
