export const isMobile = /Mobi|Android/i.test(navigator.userAgent);

export const generateDescriptionForSymbol = (symbol, title = null) => {
    if (!symbol) return null;

    const prefixTitles = {
        IMKBH: 'hisse senedi',
        IMKBX: 'endeksi',
        FX: 'kuru',
    };

    return `${title || ''} (${symbol.id}) ${
        prefixTitles[symbol.prefix]
    } hakkında detaylı ve anlık veriler, fiyatlar, günlük-aylık-yıllık grafikler, analizler, geçmiş veriler ve ${
        symbol.id
    } hisse haberleri.`;
};

export const generateTitleForSymbol = (code, prefix, title) => {
    if (prefix === 'FX') {
        return `${code} - ${title} Alış Satış Fiyatı, Grafik, Analiz, Haberler`;
    } else if (prefix === 'IMKBX') {
        return `${title} - ${code} Endeks Fiyatı, Canlı Veriler, Grafikler, Teknik ve Temel Analiz`;
    }

    return `${code} Detayları - ${title} Fiyat, Grafik, Analiz, Haberler`;
};
