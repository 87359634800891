import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MiniPriceTable, SectionHeader } from '../components';
import { selectEntities } from '../stores/lists';

const tabs = [
    { title: 'Yükselen', value: 'best' },
    { title: 'Düşen', value: 'worst' },
    { title: 'Hacim', value: 'volume' },
];

const TopStocks = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const lists = useSelector(state => selectEntities(state));

    return (
        <div className="bg-white rounded-lg shadow">
            <SectionHeader
                title="En Hisseler"
                withTabs={{ tabs, activeTabIndex, setActiveTabIndex, tabLinkClassName: 'flex-1' }}
                titleComponent="div"
            />

            {lists[tabs[activeTabIndex].value] && (
                <MiniPriceTable codes={lists[tabs[activeTabIndex].value].codes.slice(0, 10)} />
            )}
        </div>
    );
};

export default TopStocks;
