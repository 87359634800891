import React from 'react';
import { Link } from 'react-router-dom';

import Button from './Button';

const HeaderDelayedDataAlert = () => (
    <div className="bg-blue-500">
        <div className="max-w-8xl mx-auto py-2 px-4 sm:px-6">
            <div className="flex items-center justify-between flex-wrap">
                <div className="w-auto flex-1 flex items-center">
                    <span className="flex p-2 rounded-lg bg-blue-800">
                        <svg
                            className="h-6 w-6 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                            />
                        </svg>
                    </span>
                    <p className="ml-3 font-medium text-white truncate">
                        <span className="lg:hidden">Koşulsuz 2 ay canlı fiyat verisi hediye!</span>
                        <span className="hidden lg:inline">
                            Fiyatları 15dk gecikmeli takip ediyorsunuz. Ücretsiz 2 ay canlı veri
                            ister misiniz?
                        </span>
                    </p>
                </div>
                <div className="order-2 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <Link to="/tanitim" className="w-full">
                        <Button
                            className="w-full sm:w-auto"
                            ringOffsetColorClass="ring-offset-blue-500"
                            color="white"
                            label="Hemen Başvur"
                        />
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default HeaderDelayedDataAlert;
