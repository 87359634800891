import React from 'react';

import { SymbolIntradayChart } from '../../components';
import { useRequest } from '../../hooks';

const prepareResponse = res => [
    {
        id: 'price',
        color: 'rgba(35, 56, 118, 0.2)',
        data: res.map(({ x, y }) => ({ x: new Date(x), y })),
    },
];

const Chart = ({ code }) => {
    const [{ response, loading }] = useRequest(`symbols/${code}/intraday/`, {
        prepareResponse,
    });

    return loading ? <div>Yükleniyor...</div> : <SymbolIntradayChart data={response} code={code} />;
};

export default Chart;
