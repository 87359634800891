import React from 'react';
import Col from './Col';
import classNames from 'classnames';

const HeaderTicker = () => {
    const codes = ['XU100', 'USDTRY', 'EURTRY', 'GARAN', 'THYAO', 'TUPRS', 'ASELS']; // Bu backendden de gelebilir zamanla

    return (
        <div className="bg-gray-900 text-white hidden sm:flex">
            {codes.map((code, i) => (
                <div
                    key={code}
                    className={classNames(
                        'flex-1 hidden',
                        i !== codes.length - 1 && 'border-r border-gray-700',
                        i <= 2 && 'sm:block',
                        i > 5 && 'lg:block',
                        i > 2 && 'xl:block',
                    )}>
                    <Col code={code} />
                </div>
            ))}
        </div>
    );
};

export default HeaderTicker;
