import React, { useEffect, useState } from 'react';
import { Button, ModalHeader, toast } from '../../components';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import api from '../../core/api';

const FundList = ({ category, onAdd, onRemove, suggestionIds, addedFundCodes, onClose }) => {
    const [data, setData] = useState(null);
    const [openCategory, setOpenCategory] = useState(null);

    useEffect(() => {
        api.get(`funds/categories/${category.id}/`)
            .then(res => setData(res.data))
            .catch(error =>
                toast.simple({
                    title: 'Fon listesi çekilirken bir hata oluştu',
                    icon: 'error',
                }),
            );
    }, [category]);

    if (!data) {
        return null;
    }

    return (
        <>
            <div className="px-6 pt-6">
                <ModalHeader
                    title={category.title}
                    description="Portföyünüze eklemek istediğiniz fonları seçip kapatabilirsiniz. "
                    onClose={onClose}
                    noBorder
                />
            </div>
            {data.subcategories.map(cat => (
                <div key={cat.id}>
                    <div
                        className="cursor-pointer hover:bg-blue-100 bg-blue-50 px-5 py-2 border-t border-blue-100 border-t border-b block font-medium text-sm"
                        onClick={() =>
                            setOpenCategory(openCategory =>
                                openCategory === cat.id ? null : cat.id,
                            )
                        }>
                        {openCategory === cat.id ? (
                            <ChevronDownIcon className="w-5 h-5 inline" />
                        ) : (
                            <ChevronRightIcon className="w-5 h-5 inline" />
                        )}{' '}
                        {cat.title}
                        {suggestionIds.includes(cat.id) && (
                            <span className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Önerilen
                            </span>
                        )}
                    </div>
                    {openCategory === cat.id && (
                        <table className="bg-white w-full">
                            <thead>
                                <tr>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium text-center">
                                        Kod
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium ">
                                        Fon Adı
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium ">
                                        1 Ay %
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium ">
                                        3 Ay %
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium ">
                                        6 Ay %
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium ">
                                        Yılbaşı %
                                    </th>
                                    <th className="text-gray-600 bg-gray-100 text-sm py-1 font-medium "></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cat.funds.map(fund => (
                                    <tr className="border-b" key={fund.code}>
                                        <td className="text-center font-medium w-16 py-1">
                                            {fund.code}
                                        </td>
                                        <td className="text-xs truncated">{fund.title}</td>
                                        <td className="text-xs">{fund.yield_1m}</td>
                                        <td className="text-xs">{fund.yield_3m}</td>
                                        <td className="text-xs">{fund.yield_6m}</td>
                                        <td className="text-xs">{fund.yield_ytd}</td>
                                        <td className="pr-2 text-right pt-0.5 pb-1">
                                            {addedFundCodes.indexOf(fund.code) === -1 ? (
                                                <Button
                                                    size="xxs"
                                                    label="Ekle"
                                                    onClick={() => onAdd(fund)}
                                                    color="lightBlue"
                                                />
                                            ) : (
                                                <Button
                                                    size="xxs"
                                                    label="Çıkart"
                                                    onClick={() => onRemove(fund.code)}
                                                    color="red"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            ))}
        </>
    );
};

export default FundList;
