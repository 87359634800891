import { createSlice } from '@reduxjs/toolkit';

const INITIAL = {
    user: undefined,
    tokens: { access: undefined, refresh: undefined },
    fromPath: undefined,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL,
    reducers: {
        userReceived(state, action) {
            state.user = action.payload;
        },
        tokensReceived(state, action) {
            state.tokens = action.payload;
        },
        setFromPath(state, action) {
            state.fromPath = action.payload; // where did the user come from to auth?
        },
        deleteFromPath(state, action) {
            state.fromPath = undefined;
        },
    },
});

export const { userReceived, setFromPath, deleteFromPath, tokensReceived } = authSlice.actions;

export default authSlice.reducer;
