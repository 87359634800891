// @flow

import React from 'react';
import NumberFormat from 'react-number-format';

import Input from './Input';

type Params = {
    onChange: Function,
    value: any,
    errors: string | { [errorType: string]: string } | Array<string>,
    name: string,
    label?: string,
    id?: string,
    className?: string,
};

const PhoneInput = ({
    onChange,
    value,
    errors,
    name,
    label = 'Telefon',
    id = 'phone',
    className = '',
    ...rest
}: Params) => {
    return (
        <NumberFormat
            {...rest}
            value={value}
            format="+90 (###) ###-####"
            allowEmptyFormatting
            autoComplete="tel-national"
            mask="_"
            id={id}
            name={name}
            label={label}
            type="tel"
            containerClass={className}
            errors={errors}
            customInput={Input}
            onValueChange={target => {
                onChange(target.value);
            }}
        />
    );
};

export default PhoneInput;
