import React, { useMemo } from 'react';

import { SectionHeader } from '../../components';
import { useRequest, usePrice } from '../../hooks';

function toTitleLowerCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toLocaleUpperCase('tr') + txt.substr(1).toLocaleLowerCase('tr');
    });
}

const Yields = ({ code, title, prefix }) => {
    const [{ response, loading }] = useRequest(`symbols/${code}/yield/`);
    const { formatted } = usePrice(code);

    const sectionTitle = useMemo(
        () =>
            prefix
                ? prefix === 'FX'
                    ? `${toTitleLowerCase(title)} (${code}) Getiri Analizi`
                    : `${code} (${toTitleLowerCase(title)}) Getiri Analizi`
                : '',
        [prefix, code, title],
    );

    return (
        <div>
            <SectionHeader title={sectionTitle} />
            <div className="divide-y divide-gray-200">
                {loading
                    ? Array.from(Array(8).keys()).map(i => (
                          <div key={i} className="animate-pulse flex space-x-4 pt-4 pb-5 px-4">
                              <div className="flex-1 space-y-2 py-1">
                                  <div className="flex flex-row justify-between">
                                      <div className="h-3 bg-gray-200 rounded w-1/6"></div>
                                      <div className="h-3 bg-gray-200 rounded w-1/6"></div>
                                  </div>
                                  <div className="h-3 bg-gray-100 rounded w-6/6"></div>
                              </div>
                          </div>
                      ))
                    : response.map(({ low, high, title, percentage, difference }, i) => {
                          const color = difference > 0 ? 'green' : 'red';
                          const leftPercentage = Math.min(
                              Math.max(
                                  parseInt(((formatted.lastPrice - low) * 98) / (high - low)),
                                  0,
                              ),
                              98,
                          );

                          return (
                              <div key={i} className="pt-4 pb-5 px-4">
                                  <dl className="flex flex-row justify-between items-center">
                                      <dt className="font-medium">{title}</dt>
                                      <dd className={`font-semibold text-lg text-${color}-500`}>
                                          %{percentage}
                                      </dd>
                                  </dl>
                                  <div className="mt-1">
                                      <div className="flex justify-between items-center">
                                          <div className="text-sm">Dip: {low}</div>
                                          <div className="text-sm">Zirve: {high}</div>
                                      </div>
                                      <div className="bg-gray-300 relative flex items-center mt-1 rounded-full w-full h-1">
                                          <div
                                              style={{ left: `${leftPercentage}%` }}
                                              className={`h-3 w-3 rounded-full bg-${color}-500 absolute`}
                                          />
                                      </div>
                                  </div>
                              </div>
                          );
                      })}
            </div>
        </div>
    );
};

export default Yields;
