import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import CalendarTable from '../../components/CalendarTable';

const tabs = [
    { title: 'Dün', value: 'yesterday' },
    { title: 'Bugün', value: 'today' },
    { title: 'Bu hafta', value: 'week' },
    { title: 'Gelecek hafta', value: 'next-week' },
];

const TabLink = ({ active, onClick, children }) => (
    <button
        onClick={onClick}
        className={classNames(
            'cursor-pointer whitespace-nowrap py-4 px-6 border-b-2 border-transparent font-medium text-sm leading-5 focus:outline-none',
            active && 'text-blue-700 border-b-2 border-blue-600',
            !active &&
                'text-gray-500 focus:text-gray-700 focus:border-gray-300 hover:border-gray-300 hover:text-gray-700',
        )}>
        {children}
    </button>
);

const Calendar = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(1);

    return (
        <>
            <Helmet>
                <title>Ekonomik Takvim</title>
                <meta
                    name="description"
                    content="Dünya çapında yakın zamanda yaşanacak gelişmeleri ekonomik takvim ile anlık olarak takip edebilirsiniz."
                />
            </Helmet>

            <div className="bg-white rounded-lg shadow">
                <h2 className="px-6 pt-4 pb-4 text-xl justify-between flex items-center font-medium">
                    Ekonomik Takvim
                    <select
                        className="form-select md:hidden"
                        value={activeTabIndex}
                        onChange={e => setActiveTabIndex(e.target.value)}>
                        {tabs.map((tab, i) => (
                            <option key={i} value={i}>
                                {tab.title}
                            </option>
                        ))}
                    </select>
                </h2>

                <div className="border-b border-gray-200">
                    <nav className="-mb-px hidden md:flex">
                        {tabs.map((tab, i) => (
                            <TabLink
                                key={tab.title}
                                onClick={() => setActiveTabIndex(i)}
                                active={activeTabIndex === i}>
                                {tab.title}
                            </TabLink>
                        ))}
                    </nav>
                </div>

                <CalendarTable filter={tabs[activeTabIndex].value} />
            </div>
        </>
    );
};

export default Calendar;
