import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <>
            <Helmet>
                <title>Fintables PRO Hediye!</title>
                <meta
                    name="description"
                    content="Borsaekranim'a üye olan herkese Fintables PRO üyeliği hediye"
                />
            </Helmet>

            <div class="relative px-4 sm:px-6 lg:px-8">
                <div class="text-lg max-w-prose mx-auto">
                    <h1>
                        <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                            KAMPANYA
                        </span>
                        <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Borsa Ekranım’a Üye Olun, <br />
                            Ücretsiz Fintables PRO’ya Sahip Olun!
                        </span>
                    </h1>
                    <p class="mt-8 text-xl text-gray-500 leading-8">
                        18 Ağustos – 31 Ekim tarihleri arasında Borsa Ekranım'a üye olun, 1 aylık
                        Fintables PRO paketine ücretsiz sahip olun. Üstelik 2 ay Anlık Borsa
                        Verileri de Hediye!
                    </p>
                </div>
                <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <p>
                        Kampanya tarihleri arasında www.borsaekranim.com adresinden Borsa Ekranım’a
                        üye olan ilk 2.000 (ikibin) kullanıcımıza 1 aylık ücretsiz Fintables PRO
                        temel analiz üyelik paketi (*) hediye edilecektir. Hemen Borsa Ekranım’a üye
                        olarak Fintables PRO’nun zengin analiz imkânlarından yararlanabilirsiniz.
                        Üstelik Borsa Ekranım’a üye olan herkes, Anlık Borsa Verilerini Borsa
                        Ekranım üzerinden 2 ay boyunca ücretsiz olarak izleyebileceklerdir.{' '}
                        <div className="text-center mt-12">
                            <Link
                                to="/kayit-ol"
                                className="inline-flex items-center justify-center px-8 py-4 border
                                border-transparent text-base font-medium rounded-md text-white
                                bg-red-600 hover:bg-red-700 text-white cursor-pointer"
                                style={{ textDecoration: 'none' }}>
                                <span className="text-white">
                                    Kampanyaya katılmak için tıklayınız
                                </span>
                            </Link>
                        </div>
                    </p>
                    <h2>Fintables PRO Üyeliği Nedir? Ne İşe Yarar?</h2>
                    <p>
                        Fintables, Borsa İstanbul’da işlem gören şirketlerin finansal raporlarını
                        anında sisteme çekerek standardize eden ve veri görselleştirme tekniklerini
                        kullanarak yatırımcıların hızlı analiz yapmasını sağlayan bir temel analiz
                        platformudur. Rasyo analizleri ile yatırımcılara şirketleri kıyaslama imkanı
                        verir ve radar ile istenilen kriterlere uygun hisselerin listelenmesini
                        sağlar. Fintables Özet Rapor ile şirketlerin finansal raporlarını tek
                        bakışta anlamlandırmasına imkan tanır.
                    </p>
                    <p>
                        <strong>Kampanya Koşulları</strong>
                        <ul>
                            <li>
                                Kampanya 18 Ağustos – 31 Ekim 2021 tarihleri arasında geçerlidir.
                            </li>
                            <li>
                                Kampanyadan, kampanya tarihleri arasında www.borsaekranim.com web
                                sitesine üye olan ilk 2.000 (ikibin) kullanıcı yararlanacaktır. Üye
                                olan bu kullanıcılarımıza 1 aylık Fintables PRO üyeliği ücretsiz
                                olarak verilecektir.
                            </li>
                            <li>
                                Mevcutta Fintables PRO üyesi olanlar bu kampanyadan
                                yararlanamayacaktır. Kampanya dönemi içerisinde Fintables PRO
                                üyeliği bulunan ve üyeliklerini iptal ederek borsaekranim.com ‘a üye
                                olmaları durumunda, bu kullanıcılarımız kampanyadan yararlanamaz.
                            </li>
                            <li>
                                1 aylık ücretsiz Fintables PRO üyelik paketi, üye olunduktan sonra
                                tanımlanacak ve Fintables PRO kullanım bilgileri üyelik işlemleri
                                sırasında verdikleri e-posta adresine gönderilecektir. E-posta
                                adreslerinin hatalı olması veya e-posta adreslerinden kaynaklanan
                                hatalardan dolayı Fintables PRO kullanıcı bilgilerinin
                                ulaşmamasından İnfo Yatırım sorumlu değildir.
                            </li>
                            <li>Kampanya sadece Fintables PRO üyeliğini kapsayacaktır.</li>
                            <li>Her yatırımcının kampanyaya 1 kez katılım hakkı bulunmaktadır.</li>
                            <li>18 yaşından küçük olanlar bu kampanyadan yararlanamaz.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
};

export default About;
