import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import classNames from 'classnames';

import { useFavorites } from '../hooks';
import { HeaderSymbolSearch, ModalHeader } from '../components';

const EditFavoritesModalContent = ({ onClose }) => {
    const { t } = useTranslation();
    const [widths, setWidths] = React.useState([]);
    const [favorites, setFavorites] = useState([]);
    const { favorites: favoritesData, deleteFavorite, syncFavorites, addFavorite } = useFavorites();

    useEffect(() => {
        setFavorites(favoritesData);
    }, [favoritesData]);

    return (
        <div>
            <ModalHeader onClose={onClose} title={t('action.editFavorites')} />
            <div className="mt-4">
                <HeaderSymbolSearch
                    onSelected={symbol => {
                        addFavorite(symbol.id);
                    }}
                    placeholder={t('action.addFavorite')}
                    inputClassName="border-gray-300"
                />
            </div>
            <div className="mt-4">
                <List
                    beforeDrag={({ elements, index }) => {
                        const cells = Array.from(elements[index].children);
                        const widths = cells.map(cell => window.getComputedStyle(cell).width);
                        setWidths(widths);
                    }}
                    values={favorites}
                    onChange={({ oldIndex, newIndex }) => {
                        setFavorites(arrayMove(favorites, oldIndex, newIndex));
                        syncFavorites(arrayMove(favorites, oldIndex, newIndex));
                    }}
                    renderList={({ children, props, isDragged }) => (
                        <table
                            className="min-w-full divide-y divide-gray-200"
                            style={{
                                cursor: isDragged ? 'grabbing' : undefined,
                            }}>
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Sembol
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200" {...props}>
                                {children}
                            </tbody>
                        </table>
                    )}
                    renderItem={({ value, props, isDragged, isSelected }) => {
                        const _widths = isDragged ? widths : [];
                        const row = (
                            <tr
                                {...props}
                                className={classNames(isDragged || isSelected ? 'bg-gray-100' : '')}
                                style={{
                                    ...props.style,
                                    cursor: isDragged ? 'grabbing' : 'grab',
                                }}>
                                <td
                                    style={{ width: _widths[0] }}
                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-12">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 8h16M4 16h16"
                                        />
                                    </svg>
                                </td>
                                <td
                                    style={{ width: _widths[1] }}
                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {value}
                                </td>
                                <td
                                    style={{ width: _widths[2] }}
                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                                    <button
                                        onClick={() => {
                                            deleteFavorite(value);
                                        }}
                                        className="ml-4 text-red-500 focus:border-red hover:text-red-700 text-sm leading-5 font-medium">
                                        {t('action.remove')}
                                    </button>
                                </td>
                            </tr>
                        );

                        return isDragged ? (
                            <table
                                className="divide-y divide-gray-200 z-50"
                                style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody className="divide-y divide-gray-200">{row}</tbody>
                            </table>
                        ) : (
                            row
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default EditFavoritesModalContent;
