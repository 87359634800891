import React, { useMemo } from 'react';

import { NewsList as List, SectionHeader } from '../../components';
import { useRequest } from '../../hooks';

const NewsList = ({ code, prefix, data }) => {
    const [{ response, loading }] = useRequest(`symbols/${code}/news/`);
    const sectionTitle = useMemo(
        () =>
            prefix
                ? prefix === 'FX'
                    ? `${code} - Haberler ve Yorumlar`
                    : prefix === 'IMKBH'
                    ? `${code} (${data.title || ''}) Haberler ve Yorumlar`
                    : 'Haberler & Yorumlar'
                : '',
        [prefix, code, data],
    );

    return (
        <>
            <SectionHeader title={sectionTitle} />

            <List news={response} loading={loading} />
        </>
    );
};

export default NewsList;
