import React, { useState } from 'react';

import NewItem from './NewItem';
import NewDetailsModalContent from './NewDetailsModalContent';
import Modal from '../Modal';

const NewsList = ({ news = [], loading = false }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedNew, setSelectedNew] = useState(null);

    return (
        <div>
            <div className="relative mx-auto lg:max-w-8xl">
                <div className="flex flex-col space-y-5 px-6 py-5">
                    {loading && news.length === 0 ? (
                        Array.from(Array(10).keys()).map(i => (
                            <div key={i} className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-2 py-1">
                                    <div className="h-3 bg-gray-200 rounded w-1/6"></div>
                                    <div className="h-3 bg-gray-100 rounded w-5/6"></div>
                                </div>
                            </div>
                        ))
                    ) : !loading && news.length === 0 ? (
                        <div className="flex flex-1 flex-col justify-center">
                            <div className="text-md">Güncel haber bulunamadı.</div>
                        </div>
                    ) : (
                        news.map((item, i) => (
                            <NewItem
                                onClick={item => {
                                    setSelectedNew(item);
                                    setShowModal(true);
                                }}
                                item={item}
                                key={i}
                            />
                        ))
                    )}
                </div>

                <Modal
                    onClose={() => setShowModal(false)}
                    contentClassName="bg-white p-6 m-8 max-w-prose w-full"
                    show={showModal}>
                    <NewDetailsModalContent
                        onClose={() => setShowModal(false)}
                        newItem={selectedNew}
                        loading={loading}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default NewsList;
