// @flow

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { timeFormatDefaultLocale } from 'd3-time-format';
import 'moment/locale/tr';
import 'moment/locale/ar';

import { setLanguageToApis } from '../core/api';
import tr from '../translations/tr';
import ar from '../translations/ar';

const resources = {
    tr: {
        translation: tr,
    },
    ar: {
        translation: ar,
    },
};

export default function initI18n(language: 'tr' | 'ar' = 'tr') {
    moment.locale(language);
    setLanguageToApis(language);

    // todo:: get from remote json file
    timeFormatDefaultLocale({
        dateTime: '%a %e %b %X %Y',
        date: '%d/%m/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        shortDays: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
        months: [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
        ],
        shortMonths: [
            'Oca',
            'Şub',
            'Mar',
            'Nis',
            'May',
            'Haz',
            'Tem',
            'Ağu',
            'Eyl',
            'Eki',
            'Kas',
            'Ara',
        ],
    });

    i18n.use(initReactI18next).init({
        resources,
        lng: language,
        fallbackLng: 'tr',
        debug: process.env.NODE_ENV !== 'production',

        interpolation: {
            escapeValue: false,
        },
    });
}
