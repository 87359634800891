// @flow

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { selectById as selectSymbolById } from '../../stores/symbols';
import { InfoAd, PageHeader, Tippy } from '../../components';
import { useRequest, useFavorites, useUser } from '../../hooks';
import BasicAnalysisTable from './BasicAnalysisTable';
import TableView from './TableView';
import Chart from './Chart';
import HeadStats from './HeadStats';
import Yields from './Yields';
import NewsList from './NewsList';
import { getSymbolPath } from '../../routes/helpers';
import { generateDescriptionForSymbol, generateTitleForSymbol } from '../../utils/helpers';

const customDescriptions = {
    XU100:
        'BIST 100 Endeksi, Borsa İstanbul’da işlem gören, halka açık piyasa değeri ve işlem hacmi açısından önde olan 100 hisse senedinden oluşur. Hisse senedi piyasasının ana endeksi ve göstergesidir. Bist50 ve Bist30 hisselerini de içinde barındırır.',
    XU050:
        'BIST 50 Endeksi, Borsa İstanbul’da işlem gören halka açık piyasa değeri açısından en değerli ve işlem hacimleri yüksek 50 şirketin bulunduğu endekstir.',
    XU030:
        'BIST 30 Endeksi, piyasa değeri ve işlem hacmine göre ilk 30’da olan şirketlerden oluşan Borsa İstanbul endeksidir. Ana gösterge endekslerden biridir ve vadeli işlem opsiyon piyasasında vadeli kontratları bulunur.',
    XBANK:
        'BIST Bankacılık Endeksi, Borsa İstanbul bünyesinde işlem gören 10 adet banka hisse senedinden oluşur. Endeksteki banka hisseleri yıldız pazarda işlem görenler arasından seçilir. Bu endeks üzerine de VİOP kontratları bulunmaktadır.',
};

const SymbolDetail = () => {
    const { t } = useTranslation();
    const { symbol } = useParams();
    const symbolObj = useSelector(state => selectSymbolById(state, symbol));
    const user = useUser();
    const { deleteFavorite, addFavorite, isFavorite, requestLoading } = useFavorites();
    const [{ response, loading }] = useRequest(`symbols/${symbol}/overview/`);
    const symbolIsFavorite = useMemo(() => isFavorite(symbol), [symbol, isFavorite]);
    const [{ response: similarResponse, loading: similarLoading }] = useRequest(
        `symbols/${symbol}/similar/`,
    );

    const handleFavoriteButtonClick = () => {
        if (isFavorite(symbol)) {
            deleteFavorite(symbol);
        } else {
            addFavorite(symbol);
        }
    };

    useEffect(() => {
        window.document.body.id = symbol;
        return () => document.body.removeAttribute('id');
    }, [symbol]);

    const title = useMemo(
        () =>
            symbolObj
                ? generateTitleForSymbol(symbol, symbolObj.prefix, symbolObj?.title || '')
                : null,
        [symbolObj],
    );

    const canonical = `https://borsaekranim.com${getSymbolPath(symbolObj)}`;
    const description = generateDescriptionForSymbol(symbolObj, symbolObj?.title);
    const ogImageUrl = `https://api.borsaekranim.com/static/og/${symbol}.jpg`;

    let pageTitle = symbol;
    let pageTitleMini = symbolObj?.title;

    if (symbolObj?.prefix === 'IMKBX') {
        pageTitle = symbolObj?.title;
        pageTitleMini = `${symbol} ENDEKSİ`;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:type" content="article" />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />
                <meta property="og:image" content={ogImageUrl} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="596" />
                <meta property="twitter:image" content={ogImageUrl} />

                {symbolObj && [
                    <link key="1" rel="canonical" href={canonical} />,
                    <meta key="2" property="og:url" content={canonical} />,
                    <meta key="3" property="twitter:url" content={canonical} />,
                ]}

                <meta property="og:title" content={title} />
                <meta property="og:image:alt" content={title} />
                <meta property="twitter:image:alt" content={title} />
                <meta property="twitter:title" content={title} />
            </Helmet>

            <div className="flex flex-wrap mx-auto">
                <PageHeader
                    title={pageTitle}
                    titleMiniText={pageTitleMini}
                    actionButtons={[
                        {
                            title: symbolIsFavorite ? 'Favorilerden Kaldır' : 'Favorilere Ekle',
                            onClick: handleFavoriteButtonClick,
                            color: 'transparentGray',
                            disabledClassName: 'cursor-not-allowed hover:opacity-50',
                            disabled: requestLoading || !user,
                            svg: symbolIsFavorite ? (
                                <svg
                                    className="-ml-1 mr-2 h-5 w-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>
                            ) : (
                                <svg
                                    className="-ml-1 mr-2 h-5 w-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor">
                                    <path
                                        d="M11.0489 2.92707C11.3483 2.00576 12.6517 2.00576 12.9511 2.92707L14.4697 7.60083C14.6035 8.01285 14.9875 8.29181 15.4207 8.29181H20.335C21.3037 8.29181 21.7065 9.53143 20.9228 10.1008L16.947 12.9894C16.5965 13.244 16.4499 13.6954 16.5838 14.1074L18.1024 18.7812C18.4017 19.7025 17.3472 20.4686 16.5635 19.8992L12.5878 17.0107C12.2373 16.756 11.7627 16.756 11.4122 17.0107L7.43647 19.8992C6.65276 20.4686 5.59828 19.7025 5.89763 18.7812L7.41623 14.1074C7.5501 13.6954 7.40344 13.244 7.05296 12.9894L3.07722 10.1008C2.2935 9.53143 2.69628 8.29181 3.665 8.29181H8.57929C9.01251 8.29181 9.39647 8.01285 9.53034 7.60083L11.0489 2.92707Z"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ),
                            Render: ButtonComponent => {
                                if (user) {
                                    return <ButtonComponent />;
                                } else {
                                    return (
                                        <Tippy
                                            placement="left"
                                            content={t('error.loginRequiredForFavorites')}
                                            className="text-center bg-gray-900 text-gray-200 font-light">
                                            <span tabIndex="0">
                                                <ButtonComponent />
                                            </span>
                                        </Tippy>
                                    );
                                }
                            },
                        },
                    ]}
                />

                <div className="w-full mb-4">
                    <HeadStats data={response} code={symbol} />
                </div>
                <div className="w-full lg:w-8/12">
                    <div className="bg-white mb-4 w-full rounded-lg shadow">
                        <Chart prefix={symbolObj?.prefix} code={symbol} data={response} />
                    </div>
                    <div className="mb-4 w-full">
                        <TableView loading={loading} data={response} />
                    </div>
                    {symbolObj?.prefix === 'IMKBH' && (
                        <div className="mb-4 w-full rounded-lg shadow overflow-hidden">
                            <BasicAnalysisTable code={symbol} title={symbolObj?.title} />
                        </div>
                    )}

                    <div className="bg-white mb-4 w-full rounded-lg shadow">
                        <NewsList
                            loading={loading}
                            data={response}
                            code={symbol}
                            prefix={symbolObj?.prefix}
                        />
                    </div>

                    {customDescriptions[symbol] && (
                        <div className="bg-white mb-4 w-full rounded-lg shadow p-4">
                            {customDescriptions[symbol]}
                        </div>
                    )}
                </div>

                <div className="lg:pl-6 mt-4 lg:mt-0 flex-shrink-0 w-full lg:w-4/12">
                    <InfoAd />

                    <div className="mt-4 bg-white mb-4 w-full rounded-lg shadow">
                        <Yields prefix={symbolObj?.prefix} code={symbol} title={symbolObj?.title} />
                    </div>

                    <div className="mt-4 bg-white mb-4 w-full rounded-lg shadow">
                        {similarResponse?.map(row => (
                            <h3 key={row.id} className="border-b">
                                <Link
                                    className="block p-4 hover:bg-gray-100"
                                    to={getSymbolPath(row)}
                                    title={generateTitleForSymbol(row.id, row.prefix, row.title)}>
                                    {row.title} ({row.id})
                                </Link>
                            </h3>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SymbolDetail;
