import React, { useState } from 'react';
import logo from '../assets/infoyatirim-logo.png';
import { Modal, ContactUsModalContent, Button } from '../components';

const points = ['Düşük ve şeffaf komisyon oranları', 'Ücretsiz canlı veri', 'Yatırım danışmanlığı'];

const InfoAd = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="rounded-lg bg-gray-900 text-white shadow-lg p-6">
            <img src={logo} alt="Info Yatırım" className="h-10 mb-4" />
            <p className="text-gray-300">
                Bir telefon ile Info Yatırım müşterisi olarak sahip olabileceğiniz avantajlardan
                bazıları:
            </p>
            <ul className="mt-6">
                {points.map(point => (
                    <li className="flex mb-2" key={point}>
                        <div className="flex-shrink-0">
                            <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <p className="ml-3 leading-5 text-gray-50">{point}</p>
                    </li>
                ))}

                <Button
                    onClick={() => setShowModal(true)}
                    className="w-full mt-6"
                    size="lg"
                    ringOffsetColorClass="ring-offset-gray-900"
                    label="Sizi arayalım"
                />
            </ul>

            <Modal
                position="top"
                contentClassName="bg-white m-8 w-full md:w-3/4 xl:w-1/2"
                show={showModal}
                onClose={() => setShowModal(false)}>
                <ContactUsModalContent onClose={() => setShowModal(false)} />
            </Modal>
        </div>
    );
};

export default InfoAd;
