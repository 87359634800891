import React from 'react';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../components';

const Kvkk = () => {
    return (
        <>
            <Helmet>
                <title>KVKK Aydınlatma Metni</title>
            </Helmet>

            <div class="relative max-w-8xl mx-auto">
                <PageHeader title="KVKK Aydınlatma Metni" />

                <div className="rounded shadow bg-white p-4">
                    <p className="text-center font-bold">İNFO YATIRIM MENKUL DEĞERLER A.Ş</p>
                    <p className="text-center font-bold mb-4">
                        İletişim ve Bilgi Talep Formlarında İşlenen Kişisel Verilere Yönelik
                        Aydınlatma Metni
                    </p>

                    <div className="text-jusfiy">
                        <p className="mb-4">
                            İNFO YATIRIM MENKUL DEĞERLER A.Ş olarak (“İNFO”) işlediğimiz kişisel
                            verilerinizin korunmasına önem veriyoruz. Bu nedenle kişisel
                            verilerinizin 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“KVKK”)
                            uygun işlenerek, muhafaza edilmesi hususunda gereken bütün gayret ve
                            özeni gösteriyoruz. Veri Sorumlusu sıfatıyla İNFO, kişisel verilerinizle
                            ilgili bütün işlemlerde verilerinizin korunması için gereken idari ve
                            teknik tedbirleri almakta, KVKK doğrultusunda kişisel verilerinizi
                            aşağıda açıklanan şartlarda ve mevzuatta öngörülen sınırlar dâhilinde
                            işlemektedir.
                        </p>

                        <p className="mb-4">
                            <strong>Veri Sorumlusunun Kimliği ve İletişim Bilgileri</strong>
                            <table>
                                <tr>
                                    <td valign="top">Veri sorumlusu</td>
                                    <td valign="top">:</td>
                                    <td>
                                        İNFO YATIRIM MENKUL DEĞERLER A.Ş.
                                        <br />
                                        (Boğaziçi Kurumlar V.D. 4780036787)
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">Adres</td>
                                    <td valign="top">:</td>
                                    <td>
                                        Kuştepe Mah. Mecidiyeköy Yolu Cad. No:14 Kat:8-9
                                        Mecidiyeköy- Şişli/İstanbul
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">Tel</td>
                                    <td valign="top">:</td>
                                    <td>0212 324 84 26</td>
                                </tr>
                                <tr>
                                    <td valign="top">E-posta</td>
                                    <td valign="top">:</td>
                                    <td>iletisim@infoyatirim.com.tr</td>
                                </tr>
                            </table>
                        </p>

                        <p className="mb-4">
                            <strong>Kişisel Verilerinizin İşlenme Amaçları</strong>
                            <br />
                            Kişisel verileriniz;
                            <ul className="list-disc pl-4">
                                <li>
                                    İNFO tarafından ve kişisel verilerinizin mevzuata uygun olarak
                                    aktarıldığı üçüncü kişiler tarafından sunulan ürün ve
                                    hizmetlerden müşterilerimizin en iyi şekilde faydalanması için
                                    çalışmaların yapılması,
                                </li>
                                <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,</li>
                                <li>
                                    İNFO tarafından sunulan ürün ve/veya hizmetlerin satış ve
                                    pazarlama süreçlerinin, müşteri ilişkilerinin yürütülmesi,{' '}
                                </li>
                                <li>
                                    Reklam, kampanya ve promosyon faaliyetleri kapsamında müşteri ve
                                    verisi işlenen ilgili kişilerle iletişime geçilmesi, sosyal
                                    medya ve diğer elektronik mecralardan yapılacak kampanyalar,
                                    abonelikler ve veri analizi uygulamaları,
                                </li>
                                <li>
                                    Veri sorumlusu olarak Şirketimizin hukuki yükümlülüklerini
                                    yerine getirebilmesi,
                                </li>
                                <li>
                                    İNFO’ya talep ve şikâyetlerini iletenler ile iletişime
                                    geçilmesi, amaçları ile işlenir.
                                </li>
                            </ul>
                        </p>

                        <p className="mb-4">
                            <strong>
                                İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
                            </strong>
                            <br />
                            Kişisel verileriniz yukarıda belirtilen amaçlar kapsamında KVKK’un 8. ve
                            9. maddelerinde belirtilen kişisel veri işleme şartları çerçevesinde;
                            İNFO’nun hukuki yükümlülüklerini ve ticari faaliyetlerini yerine
                            getirmesi için gerekli olan hallerde iş ortaklarımız, ifa
                            yardımcılarımız, danışman firmalarımız, tedarikçilerimiz, doğrudan ve
                            dolaylı hissedarlarımız, iştiraklerimiz ve bağlı ortaklıklarımız,
                            Şirketimiz çalışanları, Şirketimizce destek hizmeti verilen veya hizmet
                            alınan üçüncü kişiler, grup şirketlerimiz, özel kurum ve kuruluşlar ile
                            kamu kurum ve kuruluşlarına aktarılabilecektir. Kişisel verilerinizin
                            paylaşma süreci boyunca ve sonrasında veri güvenliğinin sağlanması için
                            her türlü teknik ve idari tedbir alınmaktadır.
                        </p>

                        <p className="mb-4">
                            <strong>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong>
                            Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle ve
                            farklı kanallardan ofisler, çağrı merkezi, internet sitesi, sosyal medya
                            mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, fiziki
                            ortamda, elektronik ortamda ya da kapalı devre görüntüleme sistemleri
                            vasıtasıyla ve diğer yöntemlerle yasal yollardan elde edilebilmektedir.
                            Ayrıca toplanan kişisel verileriniz, Şirketimiz tarafından verilen
                            hizmet, ürün ya da ticari faaliyete bağlı olarak değişkenlik
                            gösterebilir.
                        </p>
                        <p className="mb-4">
                            Kişisel verileriniz, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun
                            5. Maddesinin 1. Fıkrasında yer alan ilgili kişinin açık rızasının
                            olması hukuki sebebine dayalı olarak işlenir. Rızanızın geri alınması
                            halinde ise kişisel verileriniz anonimleştirilecektir ve
                            kullanılmayacaktır. Şirket, periyodik olarak verilerin
                            anonimleştirilmesi gerekip gerekmediğini denetlemekte ve re’sen de
                            anonimleşmesi gereken verileri anonimleştirmektedir.
                        </p>
                        <p className="mb-4">
                            <strong>Veri Sahibi (İlgili Kişi) Olarak Haklarınız</strong>
                            Veri sahibi (ilgili kişi) olarak Kişisel Verilerin Korunması Kanununun
                            11. maddesinde belirtilen haklarınıza ilişkin taleplerinizi aşağıda
                            belirtilen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz
                            niteliğine göre, talebi en geç otuz gün içinde ücretsiz olarak
                            sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi
                            hâlinde, Kişisel Verileri Koruma Kurulu’nca belirlenen tarifedeki ücreti
                            alınabilecektir. Verisi işlenen ilgili kişinin KVKK’un 11. maddesinde
                            belirtilen;
                            <ul className="list-disc pl-4">
                                <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                                <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                                <li>
                                    Kişisel verilerin işlenme amacını ve bunların amacına uygun
                                    kullanılıp kullanılmadığını öğrenme,
                                </li>
                                <li>Kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                                <li>
                                    Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                                    bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                                    kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                                    isteme,
                                </li>
                                <li>
                                    Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
                                    olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
                                    kalkması hâlinde kişisel verilerinizin silinmesini veya yok
                                    edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel
                                    verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                                </li>
                                <li>
                                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                                    ortaya çıkmasına itiraz etme,
                                </li>
                                <li>
                                    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                                    zarara uğraması hâlinde zararın giderilmesini talep etme hakları
                                    bulunmaktadır.
                                </li>
                            </ul>
                        </p>

                        <p className="mb-4">
                            <strong>Başvurunun Yapılması ve Bilgi Alma Hakkı </strong>
                            KVK Kanununun 13. maddesinin 1. fıkrası gereğince, aynı kanunun 11.
                            maddesinde belirtilen haklarınızı kullanmak ile ilgili talebinizi,
                            yazılı olarak ya da şirketimize daha önce bildirdiğiniz ve sistemimizde
                            kayıtlı bulunan elektronik posta adresini kullanmak suretiyle
                            iletisim@infoyatirim.com.tr adresine iletebilirsiniz. Ayrıca, KVKK’da
                            belirtilen ve belirtilecek diğer yöntemler ile de şirketimize başvuruda
                            bulunabilirsiniz. Gerekli açıklamalar ile başvuru ve bilgi talep formuna
                            internet sitemizdeki linkten ulaşabilirsiniz.{' '}
                            <a
                                href="https://infoyatirim.com/yuklenenler/2020/03/Kvkk_Formu.pdf"
                                target="_blank">
                                (Başvuru Formu)
                            </a>
                            <br />
                            a) Adınız, soyadınız ve başvuru yazılı ise imzanız,
                            <br />
                            b) Türkiye Cumhuriyeti vatandaşı iseniz T.C. kimlik numarası, yabancı
                            iseniz uyruğunuz, pasaport numaranız veya varsa kimlik numaranız,
                            <br />
                            c) Tebligata esas yerleşim yeri veya iş yeri adresiniz,
                            <br />
                            ç) Varsa bildirime esas elektronik posta adresiniz, telefon ve faks
                            numaranız,
                            <br />
                            d) Talep konunuzun bulunması gerekmektedir. Konuya ilişkin bilgi ve
                            belgeler başvuruya eklenmelidir
                            <br />
                            Şirketimiz, bu kapsamdaki taleplerinizi, talebin niteliğine göre en kısa
                            sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
                            Ancak, işlemin ayrıca bir maliyeti gerektirmesi halinde, Kurulca
                            belirlenen tarifedeki ücret alınabilecektir. Ayrıca başvurularınızın
                            sadece sizlerle ilgili kısmı cevaplanacak olup, eşiniz, yakınınız ya da
                            arkadaşınız hakkında yapılan bir başvuru kabul edilmeyecektir.
                        </p>

                        <p className="mb-4"></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kvkk;
