import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Modal, ContactUsModalContent } from '../../components';

const nonInfoPoints = [
    <span>
        <strong>2 ay ücretsiz</strong> anlık fiyat verisi
    </span>,
    'Ücretsiz 2 Ay Anlık Borsa Verisi*',
    'Ücretsiz Temel Analiz Verileri',
    'Ücretsiz Teknik Analiz Verileri',
];

const infoPoints = [
    <span>
        <strong>Her zaman ücretsiz</strong> anlık fiyat verisi
    </span>,
    'Teknik analiz',
    'Temel analiz',
    'Yatırım danışmanlığı',
    'Özel bültenler',
    'Al/sat sinyalleri',
    'Algoritmik işlemler',
];

const Intro = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Helmet>
                <title>Üyelik Avantajları</title>
                <meta
                    name="description"
                    content="Borsa Ekranım müşterisi olmanın ayrıcalıklarını ve avantajlarını keşfedin!"
                />
            </Helmet>

            <div className="bg-gray-900 -mt-4 -mb-6 -mx-4 sm:-mx-6">
                <div className="pt-12 sm:pt-16 lg:pt-24">
                    <div className="container mx-auto text-center px-3 lg:px-0">
                        <div className="max-w-3xl mx-auto lg:max-w-none">
                            {/*<h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                                INFO TRADE HERKESE AVANTAJ SAĞLIYOR
                            </h2>*/}
                            <p className="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                                Size Özel Bir Teklifimiz Var!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
                    <div className="relative">
                        <div className="absolute inset-0 h-3/4 bg-gray-900"></div>
                        <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
                                <div className="rounded-lg shadow-lg overflow-hidden">
                                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                        <div>
                                            <h3
                                                className="inline-flex py-1 text-sm leading-5 font-semibold tracking-wide uppercase text-indigo-600"
                                                id="tier-standard">
                                                HERKESE ÜCRETSİZ VE KOŞULSUZ ANLIK BORSA VERİSİ!
                                            </h3>
                                        </div>
                                        <p className="mt-5 text-lg leading-7 text-gray-500">
                                            Üye olun{' '}
                                            <strong>
                                                2 ay ücretsiz anlık borsa fiyat bilgilerine
                                            </strong>
                                            sahip olun
                                        </p>
                                        <p className="mt-3 text-md leading-7 text-gray-500 font-medium">
                                            Üye Olma Avantajları:
                                        </p>
                                    </div>
                                    <div className="px-6 pt-2 pb-8 bg-gray-50 sm:p-10 sm:pt-2">
                                        <ul>
                                            {nonInfoPoints.map((point, i) => (
                                                <li key={point} className="flex items-start mt-3">
                                                    <div className="flex-shrink-0">
                                                        {i <= 2 ? (
                                                            <svg
                                                                className="h-6 w-6 text-green-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M5 13l4 4L19 7"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="h-6 w-6 text-red-500"
                                                                fill="currentColor"
                                                                viewBox="0 0 20 20">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                                    clipRule="evenodd"></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <p className="ml-3 text-base leading-6 text-gray-700">
                                                        {point}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="mt-6 rounded-md shadow">
                                            <Link
                                                to="/kayit-ol"
                                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 focus:ring-offset-white transition duration-150 ease-in-out"
                                                aria-describedby="tier-standard">
                                                Hemen üye ol
                                            </Link>
                                        </div>

                                        <div className="mt-3 text-xs text-gray-400">
                                            * Ücretsiz veri yayını olarak Karma Düzey 1 verisi
                                            verilecektir. Karma Düzey 1; Hisse Düzey 1, VIOP Düzey 1
                                            ve XU30 ve XU100 Borsa İstanbul Endekslerini
                                            içermektedir.
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0 border-2 border-green-500">
                                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                        <p className="text-lg leading-7 text-gray-500">
                                            Info Yatırım müşterilerinin ayrıcalıklarında süre limiti
                                            yok, her zaman geçerli.
                                        </p>
                                    </div>
                                    <div className="px-6 pt-2 pb-8 bg-gray-50 sm:p-10 sm:pt-2">
                                        <ul>
                                            {infoPoints.map(point => (
                                                <li key={point} className="flex items-start mt-3">
                                                    <div className="flex-shrink-0">
                                                        <svg
                                                            className="h-6 w-6 text-green-500"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M5 13l4 4L19 7"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base leading-6 text-gray-700">
                                                        {point}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="mt-6 rounded-md shadow">
                                            <button
                                                onClick={() => setShowModal(true)}
                                                className="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 focus:ring-offset-white transition duration-150 ease-in-out"
                                                aria-describedby="tier-enterprise">
                                                Sizi arayalım
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                <div className="max-w-md mx-auto lg:max-w-5xl">
                    <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10">
                        <h2 className="text-3xl leading-9 font-extrabold text-gray-900 mb-6">
                            Sık sorulan sorular
                        </h2>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                Komisyon oranları nedir?
                            </dt>
                            <dd className="mt-2">
                                <p className="text-base leading-6 text-gray-500">
                                    I don't know, but the flag is a big plus. Lorem ipsum dolor sit
                                    amet consectetur adipisicing elit. Quas cupiditate laboriosam
                                    fugiat.
                                </p>
                            </dd>
                        </div>
                    </div>
                </div>
            </div> */}
                </div>

                <Modal
                    position="top"
                    contentClassName="bg-white m-8 w-full md:w-3/4 xl:w-1/2"
                    show={showModal}
                    onClose={() => setShowModal(false)}>
                    <ContactUsModalContent onClose={() => setShowModal(false)} />
                </Modal>
            </div>
        </>
    );
};

export default Intro;
