import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useRequest } from '../../hooks';
import { login } from '../../utils/authMethods';
import CodeInput from './CodeInput';
import { ErrorAlert, Button } from '../../components';

const BottomInfo = ({ canResendSms, resendSmsLoading, onResendSms }) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval = null;

        if (!canResendSms) {
            setSeconds(60);

            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (canResendSms) {
            setSeconds(0);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [canResendSms]);

    return (
        <div className="mt-6 flex items-center justify-end">
            <div className="text-xs text-center leading-5">
                Güvenlik kodunun cep telefonunuza ulaşması 1 dakika kadar sürebilir. Dilerseniz{' '}
                <button
                    disabled={!canResendSms || resendSmsLoading}
                    onClick={onResendSms}
                    type="button"
                    to="forgot-password"
                    className={classNames(
                        canResendSms || resendSmsLoading
                            ? 'text-blue-600 hover:text-blue-500'
                            : 'text-blue-400 cursor-not-allowed',
                        'font-semibold focus:outline-none focus:underline transition ease-in-out duration-150',
                    )}>
                    kodu tekrar gönderelim {seconds !== 0 && `(${seconds}s)`}
                </button>
            </div>
        </div>
    );
};

const requestConfig = {
    url: '/auth/register/',
    method: 'post',
};

const PhoneVerification = ({ location: { state } }) => {
    const [{ loading, errors: apiErrors }, registerRequest] = useRequest(requestConfig);
    const [{ loading: resendSmsLoading }, resendSmsRequest] = useRequest(requestConfig);
    const [canResendSms, setCanResendSms] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        setResendSmsTimeout();
    }, []);

    const submit = (value = undefined) => {
        registerRequest({ ...state.data, sms: value ?? code }, res => {
            login(res.data);
        });
    };

    const resendSms = () => {
        resendSmsRequest(state.data, () => {
            setResendSmsTimeout();
        });
    };

    const setResendSmsTimeout = () => {
        setCanResendSms(false);

        return setTimeout(() => {
            setCanResendSms(true);
        }, 60 * 1000);
    };

    const onSubmit = e => {
        e.preventDefault();
        submit();
    };

    return (
        <>
            <svg
                className="w-14 h-14 mx-auto text-blue-500 group-hover:text-blue-400 transition ease-in-out duration-150"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                />
            </svg>
            <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Telefon doğrulama
            </h2>
            <p className="mt-2 text-center text-sm leading-5 text-gray-600 px-10">
                <span className="font-bold">{state?.data.phone}</span> numaralı cep telefonunuza SMS
                ile gönderilen güvenlik kodunu giriniz.
            </p>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <ErrorAlert className="mb-8" text={apiErrors.detail || apiErrors.sms?.[0]} />
                <form noValidate onSubmit={onSubmit}>
                    <CodeInput
                        fields={6}
                        onChange={value => setCode(value)}
                        onComplete={value => submit(value)}
                    />

                    <div className="mt-6">
                        <Button label="Onayla" loading={loading} type="submit" className="w-full" />
                    </div>

                    <BottomInfo
                        canResendSms={canResendSms}
                        resendSmsLoading={resendSmsLoading}
                        onResendSms={resendSms}
                    />
                </form>
            </div>
        </>
    );
};

export default PhoneVerification;
