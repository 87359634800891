import React, { forwardRef } from 'react';
import { Switch } from '@headlessui/react';

const ErrorText = ({ text }) => (
    <p role="alert" className="mt-2 text-sm text-red-600">
        {text}
    </p>
);

export const checkHasErrors = errors => {
    if (
        (errors instanceof Object && Object.entries(errors).length > 0) ||
        (errors instanceof Array && errors.length > 0) ||
        (errors instanceof String && errors.length > 0)
    ) {
        return true;
    }

    return false;
};

const SwitchComp = forwardRef(
    (
        {
            value,
            onChange,
            label,
            hintText = '',
            errors = [], // string | Object<{[errorType: string]: string}> | Array<string>
            containerClass,
        },
        ref,
    ) => {
        // const hasErrors = checkHasErrors(errors);
        const renderErrorTexts = () => {
            if (errors instanceof Object) {
                return Object.entries(errors).map(([type, message]) => (
                    <ErrorText key={type} text={message} />
                ));
            } else if (errors instanceof Array) {
                return errors.map((text, i) => <ErrorText key={i} text={text} />);
            } else {
                return <ErrorText text={errors} />;
            }
        };

        return (
            <div className={containerClass}>
                <Switch.Group className="flex items-center justify-between" as="div">
                    <Switch.Label className="text-sm font-medium text-gray-700">
                        {label}
                    </Switch.Label>
                    <Switch
                        as="div"
                        checked={value}
                        onChange={onChange}
                        className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        {({ checked }) => (
                            <>
                                <span
                                    aria-hidden="true"
                                    className={`${
                                        checked ? 'bg-blue-500' : 'bg-gray-200'
                                    } absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}></span>
                                <span
                                    aria-hidden="true"
                                    className={`${
                                        checked ? 'translate-x-5' : 'translate-x-0'
                                    } translate-x-0 absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}></span>
                            </>
                        )}
                    </Switch>
                </Switch.Group>
                {renderErrorTexts()}
            </div>
        );
    },
);

export default SwitchComp;
