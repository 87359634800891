import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import logo from '../../assets/borsaekranim-logo.png';
import { useRequest } from '../../hooks';
import { Input, ErrorAlert, Button } from '../../components';
import { toast } from '../../components';
import { required, emailPattern } from '../../utils/validation';
import { isMobile } from '../../utils/helpers';

const ForgotPassword = () => {
    const { register, handleSubmit, errors } = useForm({ criteriaMode: 'all' });
    const [{ loading, errors: apiErrors }, forgotPasswordRequest] = useRequest({
        url: '/auth/password/reset/',
        method: 'post',
    });
    const history = useHistory();

    const onSubmit = ({ email, password }) => {
        forgotPasswordRequest({ email }, res => {
            toast.simple({ title: 'Başarılı!', description: res.data.message, icon: 'success' });
            history.push('giris-yap');
        });
    };

    return (
        <>
            <div>
                <Link to="/" title="Borsa Ekranım">
                    <img className="h-10 w-auto" src={logo} alt="Borsa Ekranım" />
                </Link>
                <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                    Sıfırlama Talebi Gönderin
                </h2>
                <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                    E-mail adresinize gelecek olan linke tıklayarak şifrenizi sıfırlayabileceksiniz.
                </p>
            </div>

            <div className="mt-8">
                <div className="mt-6">
                    <ErrorAlert className="mb-8" text={apiErrors.detail} />
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            autoFocus={!isMobile}
                            errors={errors.email?.types || apiErrors.email}
                            ref={register({
                                required: required('Email adresi'),
                                pattern: emailPattern(),
                            })}
                            label="Email adresi"
                        />

                        <div className="mt-6 flex items-center justify-end">
                            <div className="text-sm leading-5">
                                <Link
                                    to="giris-yap"
                                    className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    Giriş yap
                                </Link>
                            </div>
                        </div>

                        <div className="mt-6">
                            <Button
                                className="w-full"
                                label="Onayla"
                                loading={loading}
                                type="submit"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
