import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageHeader, toast } from '../../components';
import api from '../../core/api';
import Steps from './Steps';
import classNames from 'classnames';

const useFunds = () => {
    const { funds } = useParams();

    const parsed = useMemo(
        () =>
            funds.split(',').map(raw => {
                const [code, weight] = raw.split(':');

                return { code, weight: parseInt(weight) };
            }),
        [funds],
    );

    return parsed;
};

const Row = row => (
    <tr
        className={classNames(
            'text-sm border-b',
            row.style === 'portfolio' && 'bg-blue-100 font-bold',
        )}>
        <td className="py-2 text-center">{row.code}</td>
        <td
            className={classNames(
                'py-2 text-center',
                row.yield_1m > 0 && 'text-green-600',
                row.yield_1m < 0 && 'text-red-600',
            )}>
            {row.yield_1m?.toFixed(2)}
        </td>
        <td
            className={classNames(
                'py-2 text-center',
                row.yield_3m > 0 && 'text-green-600',
                row.yield_3m < 0 && 'text-red-600',
            )}>
            {row.yield_3m?.toFixed(2)}
        </td>
        <td
            className={classNames(
                'py-2 text-center',
                row.yield_6m > 0 && 'text-green-600',
                row.yield_6m < 0 && 'text-red-600',
            )}>
            {row.yield_6m?.toFixed(2)}
        </td>
        <td
            className={classNames(
                'py-2 text-center',
                row.yield_1y > 0 && 'text-green-600',
                row.yield_1y < 0 && 'text-red-600',
            )}>
            {row.yield_1y?.toFixed(2)}
        </td>
        <td
            className={classNames(
                'py-2 text-center',
                row.yield_ytd > 0 && 'text-green-600',
                row.yield_ytd < 0 && 'text-red-600',
            )}>
            {row.yield_ytd?.toFixed(2)}
        </td>
    </tr>
);

const Performance = () => {
    const { t } = useTranslation();
    const funds = useFunds();
    const [data, setData] = useState(null);

    useEffect(() => {
        api.post('funds/performance/', { funds })
            .then(res => setData(res.data))
            .catch(error =>
                toast.simple({
                    title: 'Fon dağılım performansınızı oluştururken bir hata oluştu',
                    icon: 'error',
                }),
            );
    }, [funds]);

    return (
        <>
            <Helmet>
                <title>{t('fund.performance')}</title>
            </Helmet>
            <PageHeader title={t('fund.performance')} />
            <Steps activeStep={2} />

            {data === null ? (
                <div className="text-center py-6 bg-white shadow rounded-lg">
                    {t('fund.performanceLoading')}
                </div>
            ) : (
                <>
                    <div className="px-4 py-5 sm:p-6 my-6 bg-white shadow rounded-lg">
                        <dt className="text-base font-normal text-gray-900">Portföy Risk Oranı</dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                {data?.risk?.toFixed(2)}%
                            </div>
                        </dd>
                    </div>

                    <div className="flex rounded-md shadow-md bg-gray-50 overflow-hidden">
                        <table className="table w-full">
                            <thead>
                                <tr className="text-sm bg-gray-800 text-white border-b">
                                    <th className="py-2">Getiri</th>
                                    <th className="py-2">1 Ay (%)</th>
                                    <th className="py-2">3 Ay (%)</th>
                                    <th className="py-2">6 Ay (%)</th>
                                    <th className="py-2">1 Yıl (%)</th>
                                    <th className="py-2">Yılbaşı (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.absolute.map(row => (
                                    <Row key={row.code} {...row} />
                                ))}
                            </tbody>
                            <thead>
                                <tr className="text-sm bg-gray-800 text-white border-b">
                                    <th className="py-2">Relatif Getiri</th>
                                    <th className="py-2"></th>
                                    <th className="py-2"></th>
                                    <th className="py-2"></th>
                                    <th className="py-2"></th>
                                    <th className="py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.relative.map(row => (
                                    <Row key={row.code} {...row} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

export default Performance;
