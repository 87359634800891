import React from 'react';

import { Header } from '../components';
import Footer from '../components/Footer';

const AppLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex bg-gray-100">
            <div className="flex-1 overflow-y-auto transition-all ease-in-out duration-500 sm:duration-700">
                <Header />

                <main className="max-w-8xl mx-auto px-4 sm:px-6 py-4">{children}</main>

                <Footer />
            </div>
        </div>
    );
};

export default AppLayout;
